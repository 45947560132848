import React, { useState, useEffect } from 'react';
import './taskBarCan.css';
import './jobListCan.css';
import './listCardsCan.css';
import './jobDetailCan.css';
import './secondPartCan.css';
import './profileCan.css';
import Navcandidate from './Navcandidate';
import { Link } from 'react-router-dom';
import axios from 'axios';

function CandidateApplied() {
  const [jobApplications, setJobApplications] = useState([]);

  useEffect(() => {
    // Make an API call to fetch the applied jobs data
    axios.get('https://djs4331.uta.cloud/php/jobsapplied.php')
      .then((response) => {
        setJobApplications(response.data.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  // Calculate the count for each job title
  const jobTitleCount = {};
  jobApplications.forEach((application) => {
    const jobTitle = application.job_title;
    jobTitleCount[jobTitle] = (jobTitleCount[jobTitle] || 0) + 1;
  });

  return (
    <div className="can-app">
      <nav>
        <Navcandidate />
      </nav>

      <div className="can-app-body">
        <div className="can-app-body-main-content">
          <section className="can-service-section">
            <h2>Overview</h2>
            <div className="can-cards">
              <div className="can-card">
                <Link to="/CandidateApplied">
                  <h3>Total Applications</h3>
                </Link>
                <p>{jobApplications.length}</p>
              </div>
            </div>
          </section>
          <section className="service-section">
            <div>
              <h2>Job Status</h2>
              <table>
                <thead>
                  <tr>
                    <th>Applied Jobs</th>
                    <th style={{ textAlign: 'right' }}>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(jobTitleCount).map(([jobTitle, count]) => (
                    <tr key={jobTitle}>
                      <td>{jobTitle}</td>
                      <td style={{ textAlign: 'right' }}>In Progress</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default CandidateApplied;
