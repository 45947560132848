import React from "react";
import './navdei.css';
import { Link } from 'react-router-dom';


import bgimg from './images/bgimg.png';

function Navdei() {
    return (
        <div className="dei-app">
            <header className="dei-app-header">
                <div className="dei-app-header-navigation">
                    <div className="dei-tabs active">
                         
                         <Link to="/mainDashboard">Dashboard</Link>
                         <Link to="/alldeijobs">Jobs</Link>
                         <Link to="/deicandidates">Candidates</Link>
                         <Link to="/deiprofile">Profile</Link>
                         <Link to="/deiInitiatives">Initiatives</Link>
                        <a href = 'http://kxs3150.uta.cloud/'>Blog</a>
                    </div>
                </div>
                <div className="dei-app-header-actions">
                  <div className="dei-dropdown">
                    <button className="dei-user-profile">
                        <span>Officer Name</span>
                        <span>
                            <img src={bgimg} alt='background_image' />
                        </span>
                    </button>
                    <div className="dei-app-header-actions-buttons">
                            <div className="dei-dropdown-content">
                               <button> <Link to="/deiprofile">Edit Profile</Link></button>
                                <button><Link to="/Home">Logout</Link></button>
                                
                          </div>
                        </div>
                    </div>
                    </div>
        
            </header>
            </div>
    );
}

export default Navdei;
