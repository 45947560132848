import React from 'react';

import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navacademia from './Navacademia';


function UpdateProfile() {



  const hrStyle = {
    width: '98%',
    marginLeft: '1px',
    textAlign: 'left',
  };


  const navigate = useNavigate();

//   const [file, setFile] = useState(null);

// const handleFileChange = (e) => {
//   const selectedFile = e.target.files[0];
//   setFile(selectedFile);
// };


  //enter academia details.
  const [aid, setAid] = useState('');
  const [name, setName] = useState('');
  const [addr, setAdddr] = useState('');
  const [Adescription, setAdescription] = useState('');

  const [responseMessage, setResponseMessage] = useState('');

 const handleSubmit = (event) => {
  event.preventDefault();
  const url = "https://mxs4224.uta.cloud/php/saveFormData.php";

  let fData = new FormData();

  fData.append('aid',aid);
  fData.append('name',name);
  fData.append('addr',addr);
  fData.append('description',Adescription);
  fData.append('user_id',sessionStorage.getItem('uid') );
  // fData.append('academiaFile', file );

  
  axios.post(url, fData)
  .then((res) => {
    if (res.data === "OK") {
      navigate('/profile_aca');
    } else {
      alert(res.data);
    }
  })
  .catch((err) => alert(err));
 }


 //for Faculty

  // const [aid, setAid] = useState('');
  const [fname, setFName] = useState('');
  const [qualification, setQualification] = useState('');



  const addFaculty = (event) => {
    event.preventDefault();
    const url = "https://mxs4224.uta.cloud/php/addFaculty.php";
  
    let fData = new FormData();
  
    fData.append('user_id',sessionStorage.getItem('uid') );
    fData.append('fname',fname);
    fData.append('qualification',qualification);

  
    
    axios.post(url, fData)
    .then((res) => {
      if (res.data === "OK") {
       alert('Faculty Added');
      } else {
        alert(res.data);
      }
    })
    .catch((err) => alert(err));
   }

   //for research

   const [rtitle, setRTtile] = useState('');
   const [rdesc, setRDescription] = useState('');

   const addResearch = (event) => {
    event.preventDefault();
    const url = "https://mxs4224.uta.cloud/php/addResearch.php";
  
    let fData = new FormData();
  
    fData.append('user_id',sessionStorage.getItem('uid') );
    fData.append('rtitle',rtitle);
    fData.append('rdesc',rdesc);

  
    
    axios.post(url, fData)
    .then((res) => {
      if (res.data === "OK") {
       alert('Research Added');
      } else {
        alert(res.data);
      }
    })
    .catch((err) => alert(err));
   }


  return (

    <div>
      <nav>
        <Navacademia/>
      </nav>
      <br/>
 <form className="aRegForm"  onSubmit={handleSubmit} encType="multipart/form-data">
    <div className="academia_registration">
      <h1>Academia Information</h1>

      <label htmlFor="aid"><b>Academia Id</b></label>
      <input type="text" placeholder="Enter Academia Id. Ex: UTA" name="aid" id = "aid" value={aid} onChange={(e)=> setAid(e.target.value)} required />

      <label htmlFor="name"><b>Academia Name</b></label>
      <input type="text" placeholder="Enter Academia Name" name="name" id = "name" value={name} onChange={(e)=> setName(e.target.value)} required />

      <label htmlFor="addr"><b>Academia Address</b></label>
      <input type="text" placeholder="Enter Academia Address" name="addr" id="addr" value={addr} onChange={(e)=> setAdddr(e.target.value)} required />

      <label htmlFor="Adescription"><b>Academia Description</b></label>
      <input type="text" placeholder="Description" name="Adescription" id="Adescription" value={Adescription} onChange={(e)=> setAdescription(e.target.value)} required />

      {/* <label htmlFor="myFile" className="file-upload-btn">Upload Academia Certificate
        <input type="file" id="myFile" name="filename" />
      </label> */}

      <br />

      <button type="submit">Update Academia Info</button>
    </div>
    
  </form>
  <br></br>
   <hr style={hrStyle} />
    <div className="two-columns-grid">
      <div>
        <label htmlFor="myFile" className="dashboard-heading">
          Add Faculty
        </label>
        <br />
        <div className="secondgrid">
          <form style={{ alignItems: 'center' }}  onSubmit={addFaculty}>
            <div className="academia_registration">
              <label htmlFor="aname"><b>Faculty Name</b></label>
              <input type="text" placeholder="Enter Faculty Name" name="fname" required value={fname} onChange={(e)=> setFName(e.target.value)}/>

              <label htmlFor="addr"><b>Faculty Qualification</b></label>
              <input type="text" placeholder="Enter Faculty Qualification" name="qualification" required value={qualification} onChange={(e)=> setQualification(e.target.value)} />

              <br />
              <button type="submit">Add Faculty</button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <label htmlFor="myFile" className="dashboard-heading">
          Add Researches/ Focus Areas
        </label>

        <br />
        <div className="secondgrid">
          <form className="addRes"  onSubmit={addResearch}>
            <div className="academia_registration">
              <label htmlFor="aname"><b>Title</b></label>
              <input type="text" placeholder="Title" name="title" required  value={rtitle} onChange={(e)=> setRTtile(e.target.value)}/>

              <label htmlFor="addr"><b>Description</b></label>
              <input type="text" placeholder="Description" name="description" required  value={rdesc} onChange={(e)=> setRDescription(e.target.value)}/>

              <br />
              <button type="submit">Add Research / FocusArea</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <br></br>
      <br></br>
    </div>
  );
}

export default UpdateProfile;
