import React, { useState, useEffect } from 'react';
import './deicandidates.css';
import Navdei from './Navdei';
import { Link } from 'react-router-dom';
import axios from "axios";

function formatDate(timestamp) {
    const dateObject = new Date(timestamp);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

const Deicandidates = () => {

  const [events, setEvents] = useState([]);

  const handleBookmark = (ID, isBookmarked) => {
    // Prepare the data to be sent in the request body
    const data = {
      ID: ID,
      isBookmarked: isBookmarked ? 0 : 1, // Toggle the value of isBookmarked
    };

	    // Make a POST request to update the isBookmarked field in the candidateDetails table
		axios.post('https://djs4331.uta.cloud/php/updateBookmarkStatus.php', data)
		.then((response) => {
		  if (response.data.success === 1) {
			// Success: Bookmark status updated successfully
			console.log('Bookmark status updated successfully.');
			// Update the state to reflect the new bookmark status
			setEvents(events.map((event) => {
			  if (event.ID === ID) {
				return { ...event, isBookmarked: !isBookmarked };
			  }
			  return event;
			}));
		  } else {
			// Bookmark status update failed
			console.error('Bookmark status update failed.');
		  }
		})
		.catch((error) => {
		  console.error('Error:', error);
		});
	};

  // Fetch data from the backend API endpoint
  useEffect(() => {
    axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=candidate_profile')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  const handleDelete = (ID) => {
    const primarykey = 'ID';

    axios
      .delete(`https://djs4331.uta.cloud/php/delete.php?identifier=${ID}&tableName=candidate_profile&primaryKey=${primarykey}`)
      .then((response) => {
        // Check if the deletion was successful
        if (response.data.success === 1) {
          // Update the state to remove the deleted item from the UI
          setEvents(events.filter(event => event.ID !== ID));
        } else {
          // Handle deletion failure, e.g., show an error message
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message
        console.error(error);
      });
  };
  return (
    <div className="dei-app">
      <nav>
        <Navdei/>
      </nav>
      <div className="dei-app-body">
        <div className="dei-app-body-main-content">
          <section className="dei-service-section">
            <div>
              <h2>All Candidates</h2>
              <table>
                <tr>
                  <th>Candidate Name</th>
                  <th>Email</th>
                  <th>Demographic Info</th>
                  <th>Field Of Study</th>
                  <th>Educational Background</th>
                  <th>Research Experience</th>
				  <th>Resume Link</th>
				  <th>Personal Statement/CV Link</th>
                  <th>Actions</th>
                </tr>
                {events
                  .filter(event => !event.isBookmarked) // Filter out bookmarked candidates
                  .map(event => (
                  <tr key={event.ID}>
                    <td>{event.first_name} {event.last_name}</td>
                    <td>{event.email}</td>
                    <td>{event.demographic}</td>
                    <td>{event.field_of_study}</td>
                    <td>{event.edu_background}</td>
                    <td>{event.research_exp}</td>
					<td>{event.upload_resume}</td>
					<td>{event.upload_cover_letter}</td>
          <td>{event.interest}</td>
                    <td> <td>
                      <button onClick={() => handleDelete(event.ID)} style={{ marginRight: '10px' }}>Chat</button>
                  
                    </td>
                    <td>
                     
                      
                      <button onClick={() => handleBookmark(event.ID)} style={{ marginleft: '10px' }}>Bookmark</button>
                    </td>
                    </td>
                    
                  </tr>
                ))}
              </table>
            </div>
		<table>
          <tr>
            <td style={{ textAlign: 'right' }}>
              <Link style={{ backgroundColor: 'white', textDecoration: 'none', color: 'blue' }} to="/deibookmarks">Bookmarked Candidates</Link>
            </td>
          </tr>
                  </table>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Deicandidates;