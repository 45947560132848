import React, { useState, useEffect } from 'react';
import './deiedit.css';
import Navdei from './Navdei';
import { Link } from 'react-router-dom';
import axios from "axios";

const DeiEvents = () => {
  const [events, setEvents] = useState([]);

  // Fetch data from the backend API endpoint
  useEffect(() => {
    axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=deiEvents')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  const handleDelete = (eventId) => {
    const primarykey = 'eventId';

    axios
      .delete(`https://djs4331.uta.cloud/php/delete.php?identifier=${eventId}&tableName=deiEvents&primaryKey=${primarykey}`)
      .then((response) => {
        // Check if the deletion was successful
        if (response.data.success === 1) {
          // Update the state to remove the deleted item from the UI
          setEvents(events.filter(event => event.eventId !== eventId));
        } else {
          // Handle deletion failure, e.g., show an error message
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message
        console.error(error);
      });
  };
  return (
    <div className="dei-app">
      <nav>
        <Navdei/>
      </nav>
      <div className="dei-app-body">
        <div className="dei-app-body-main-content">
          <section className="dei-service-section">
            <div>
              <h2>Events</h2>
              <table>
                <tr>
                  <th>Event Name</th>
                  <th>Event Description</th>
                  <th>Event Organizaer</th>
                  <th>Event Location</th>
                  <th>Registration Details</th>
                  <th>Event Date</th>
                  <th>Actions</th>
                </tr>
                {events.map(event => (
                  <tr key={event.eventId}>
                    <td>{event.eventName}</td>
                    <td>{event.eventDescription}</td>
                    <td>{event.eventOrganizer}</td>
                    <td>{event.eventLocation}</td>
                    <td>{event.registrationDetails}</td>
                    <td>{event.eventDate}</td>
                    <td>
                    <td>
                     <button>
                     <Link to="/deiCreateevents">Edit</Link> 
                     </button>
                    </td>
                       <td>
                      <button onClick={() => handleDelete(event.eventId)} style={{ marginRight: '10px' }}>Delete</button>
                  
                    </td>

                    </td>

                    
                  </tr>
                ))}
              </table>
              <table>
                <tr>
                  <td>
                    <button>
                      <Link to="/deiCreateevents">Generate Events</Link>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default DeiEvents;
