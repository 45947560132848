import React from 'react';
import './navadmin.css'; // Make sure to import the CSS file
import { Link } from 'react-router-dom';
import Home from '../homepage/Home';

const Navadmin = () => {
    return (
      <div className="app">
        <header className="app-header">
          <div className="app-header-navigation">
            <div className="tabs active">
        
              <Link to="/adashboard">Dash</Link>
              <Link to="/newUser">NewUser</Link>
              <Link to="/issues">Issue</Link>
  
              <Link to="/user">Users</Link>
              <Link to="/report">Report</Link>
  
              <Link to="/jobPosted">Job</Link>
              <a href="http://kxs3150.uta.cloud/">Blog</a>
            </div>
          </div>
          <div className="app-header-actions">
            <div className="dropdown">
              <button className="user-profile">
                <span>User</span>
                <span>
                  <img src="https://assets.codepen.io/285131/almeria-avatar.jpeg" alt="User Avatar" />
                </span>
              </button>
              <div className="app-header-actions-buttons">
                <button className="icon-button large">
                  <i className="ph-magnifying-glass"></i>
                </button>
                <button className="icon-button large">
                  <i className="ph-bell"></i>
                </button>
                <div className="dropdown-content">
                  <button>
                  <Link to="/adminprofile">Edit Profile</Link>
                  </button>
                  <button>
                  <Link to="/Home">Log Out</Link>

                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        </div>
    );
            }

export default Navadmin;
