
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './main_stylesheet.css';
import './createInitiative.css';
import Navdei from './Navdei';

function Deiprofile() {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    tableName: 'deiprofile',
    primaryKey: 'id',
    columns: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      myRole: '',
      city: '',
      state: '',
      zipcode: '',
      organizationName: '',
      deiGoals: '',
      deiPreferences: '',
    },
  });

  const [registerData, setRegisterData] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    city: '',
    state: '',
    zipcode: '',
  });


    // Fetch data from the backend API endpoint for deiprofile table
    useEffect(() => {
      axios
        .get(`https://djs4331.uta.cloud/php/fetch.php?tableName=deiprofile&id=${formData.columns.id}`)
        .then((response) => {
          const fetchedData = response.data;
          if (fetchedData.length > 0) {
            const firstRecord = fetchedData[0];
            setFormData((prevFormData) => ({
              ...prevFormData,
              columns: {
                ...prevFormData.columns,
                organizationName: firstRecord.organizationName,
                deiGoals: firstRecord.deiGoals,
                deiPreferences: firstRecord.deiPreferences,
              },
            }));
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setLoading(false);
        });
    }, [formData.columns.id]);


  useEffect(() => {
    // Fetch data from the backend API endpoint
    axios
      .get(`https://djs4331.uta.cloud/php/fetch.php?tableName=register&id=${formData.columns.id}`)
      .then((response) => {
        const fetchedData = response.data;
        if (fetchedData.length > 0) {
          const firstRecord = fetchedData[0];
          setFormData((prevFormData) => ({
            ...prevFormData,
            columns: {
              ...prevFormData.columns,
              id: firstRecord.id, // Set the id from the fetched data
              firstName: firstRecord.F_name,
              lastName: firstRecord.L_name,
              email: firstRecord.email,
              password: firstRecord.password,
              myRole: firstRecord.role,
              city: firstRecord.city,
              state: firstRecord.state,
              zipcode: firstRecord.zipcode,
            },
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, [formData.columns.id]);
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      columns: {
        ...prevFormData.columns,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Check if the id is not empty, indicating an existing record from the register table
    const isExistingRecord = !!formData.columns.id;
  
    // Prepare the data to be updated in the deiprofile table
    const dataForDeiprofileUpdate = {
      tableName: 'deiprofile',
      primaryKey: 'id',
      data: {
        columns: formData.columns,
      },
    };
  
    // If it's an existing record and the id is not empty, update the register table
    if (isExistingRecord) {
      // Check if any common field has changed
      const hasCommonFieldChanged =
        formData.columns.firstName !== registerData.firstName ||
        formData.columns.lastName !== registerData.lastName ||
        formData.columns.email !== registerData.email ||
        formData.columns.password !== registerData.password ||
        formData.columns.myRole !== registerData.role ||
        formData.columns.city !== registerData.city ||
        formData.columns.state !== registerData.state ||
        formData.columns.zipcode !== registerData.zipcode;
  
      if (hasCommonFieldChanged) {
        // Prepare the data to be updated in the register table
        const dataForRegisterUpdate = {
          tableName: 'register',
          primaryKey: 'id',
          data: {
            columns: {
              id: formData.columns.id,
              F_name: formData.columns.firstName,
              L_name: formData.columns.lastName,
              email: formData.columns.email,
              password: formData.columns.password,
              role: formData.columns.myRole,
              city: formData.columns.city,
              state: formData.columns.state,
              zipcode: formData.columns.zipcode,
            },
          },
        };
  
        // Make API requests to update both tables
        axios.all([
          axios.post('https://djs4331.uta.cloud/php/update.php', dataForRegisterUpdate),
          axios.post('https://djs4331.uta.cloud/php/update.php', dataForDeiprofileUpdate),
        ])
          .then(axios.spread((response1, response2) => {
            console.log(response1.data); // Response from the register update
            console.log(response2.data); // Response from the deiprofile update
            setEvents((prevEvents) => prevEvents.map(event => event.id === formData.columns.id ? formData.columns : event));
            navigate('/mainDashboard');
          }))
          .catch(function (error) {
            console.error(error);
          });
      } else {
        // If no common field changed, update only the deiprofile table
        axios.post('https://djs4331.uta.cloud/php/update.php', dataForDeiprofileUpdate)
          .then(function (response) {
            console.log(response.data); // Response from the deiprofile update
            setEvents((prevEvents) => prevEvents.map(event => event.id === formData.columns.id ? formData.columns : event));
            navigate('/MainDashboard');
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    } else {
      // It's an insert for deiprofile table
      // Make API requests to insert in deiprofile table
      axios.post('https://djs4331.uta.cloud/php/insert.php', dataForDeiprofileUpdate)
        .then(function (response) {
          console.log(response.data); // Response from the deiprofile insert
          setEvents((prevEvents) => [...prevEvents, formData.columns]);
          navigate('/');
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };
  
  
    return (
      <div>
        <nav>
          <Navdei />
        </nav>
        <div className="dei-app-body">
          <div className="dei-app-body-main-content">
            <section className="dei-service-section">
              <h2>My Profile</h2>
              <form onSubmit={handleSubmit}>
                <div className="dei-profile-section">
                  <h3>Personal Information</h3>
                  <div className="dei-form-row">
                    <div className="dei-form-field">
                      <label>First Name</label>
                      <input type="text" name="firstName" value={formData.columns.firstName || ""} onChange={handleChange} />
                    </div>
                    <div className="dei-form-field">
                      <label>Last Name</label>
                      <input type="text" name="lastName" value={formData.columns.lastName || ""} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="dei-form-row">
                    <div className="dei-form-field">
                      <label>Email</label>
                      <input type="text" name="email" value={formData.columns.email || ""} onChange={handleChange} />
                    </div>
                    <div className="dei-form-field">
                      <label>Password</label>
                      <input type="password" name="password" value={formData.columns.password || ""} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="dei-form-row">
                    <div className="dei-form-field">
                      <label>Your Role</label>
                      <input type="text" name="myRole" value={formData.columns.myRole || ""} onChange={handleChange} />
                    </div>
                    <div className="dei-form-field">
                      <label>City</label>
                      <input type="text" name="city" value={formData.columns.city || ""} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="dei-form-row">
                    <div className="dei-form-field">
                      <label>State</label>
                      <input type="text" name="state" value={formData.columns.state || ""} onChange={handleChange} />
                    </div>
                    <div className="dei-form-field">
                      <label>Zip Code</label>
                      <input type="text" name="zipcode" value={formData.columns.zipcode || ""} onChange={handleChange} />
                    </div>
                  </div>
                </div>
                <div className="dei-profile-section">
                  <h3>Organization Information</h3>
                  <div className="dei-form-row">
                    <div className="dei-form-field">
                      <label>Organization Name</label>
                      <input type="text" name="organizationName" value={formData.columns.organizationName || ""} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="dei-form-row">
                    <div className="dei-form-field">
                      <label>DEI Goals</label>
                      <input type="text" name="deiGoals" value={formData.columns.deiGoals || ""} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="dei-form-row">
                    <div className="dei-form-field">
                      <label>DEI Preferences</label>
                      <input type="text" name="deiPreferences" value={formData.columns.deiPreferences || ""} onChange={handleChange} />
                    </div>
                  </div>
                </div>
                <div className="dei-form-row">
                  <input type="submit" className="ip-button" value="Submit" />
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    );
  }
  
  export default Deiprofile;
  