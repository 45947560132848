import React from 'react';
import './style_rec.css';
import Navrecruiter from './Navrecruiter';
function UpdateProfile_rec() {
  return (
    <div>
       <nav>
        <Navrecruiter/>
      </nav>

    <div className="two-columns-grid">
     
      <div>
        <label htmlFor="myFile" className="dashboard-heading">
          Add Faculty
        </label>
        <br />
        <div className="secondgrid">
          <form style={{ alignItems: 'center' }}>
            <div className="academia_registration">
              <label htmlFor="aname"><b>Faculty Name</b></label>
              <input type="text" placeholder="Enter Faculty Name" name="fname" required />

              <label htmlFor="addr"><b>Faculty Qualification</b></label>
              <input type="text" placeholder="Enter Faculty Qualification" name="qualification" required />

              <br />
              <button type="submit">Add Faculty</button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <label htmlFor="myFile" className="dashboard-heading">
          Add Researches/ Focus Areas
        </label>

        <br />
        <div className="secondgrid">
          <form className="addRes">
            <div className="academia_registration">
              <label htmlFor="aname"><b>Title</b></label>
              <input type="text" placeholder="Title" name="title" required />

              <label htmlFor="addr"><b>Description</b></label>
              <input type="text" placeholder="Description" name="description" required />

              <br />
              <button type="submit">Add Research / FocusArea</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
}

export default UpdateProfile_rec;
