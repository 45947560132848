// SignupPage.js
import React from 'react';
import './style_aca.css';
import Navacademia from './Navacademia';
function SignupPage_aca() {
  return (
    <div><nav>
    <Navacademia/>
  </nav>
    <form className="aRegForm_aca">
      
    <div className="academia_registration">
      <h1>Academia Registration</h1>

      <label htmlFor="aname"><b>Academia Name</b></label>
      <input type="text" placeholder="Enter Academia Name" name="aname" required />

      <label htmlFor="addr"><b>Academia Address</b></label>
      <input type="text" placeholder="Enter Academia Address" name="addr" required />

      <label htmlFor="addr"><b>Academia Description</b></label>
      <input type="text" placeholder="Description" name="addr" required />

      <label htmlFor="myFile" className="file-upload-btn">Upload Academia Certificate
        <input type="file" id="myFile" name="filename" />
      </label>

      <br />

      <button type="submit">Register as Academia</button>
    </div>
  </form>
  </div>
  );
}

export default SignupPage_aca;
