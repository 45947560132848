import React from "react";
import './style_rec.css';
import Navrecruiter from "./Navrecruiter";

function Dashboard_rec() {
  return (
   <div>
       <nav>
        <Navrecruiter/>
      </nav>

    <div className="two-columns-grid">
     
    <div>
        <label for="myFile" className="dashboard-heading">
            All Jobs listings
        </label>
        <div className="secondgrid">
            <div>
                <h2>Product Management </h2>
                <p>Description of the required skills </p>
            </div>
            <div className="button-container">
                <label className="grid-button">Remove Opening</label>
            </div>
        </div>
        
        <div className="secondgrid">
            <div>
                <h2>Data Scientist</h2>
                <p>Description of the required skills</p>
            </div>
            <div className="button-container">
                <label className="grid-button">Remove Opening</label>
            </div>
        </div>

    </div>
    
</div>
</div>
  );
}

export default Dashboard_rec;


