import React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './main_stylesheet.css'; // Make sure to import the CSS file if it exists.
import './deichats.css';
import Navdei from './Navdei';
function DeiCreateevents() {
  const navigate = useNavigate();


  // State for the eventName entered by the user
  const [eventNameToDelete, setEventNameToDelete] = useState('');

  const [formData, setFormData] = useState({
    tableName: 'deiEvents',
    columns: {
      eventId: '',
      eventName: '',
      eventDescription: '',
      eventOrganizer: '',
      eventLocation: '',
      registrationDetails: '',
      eventDate:''
    }
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: value,
      },
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  
    axios.post('https://djs4331.uta.cloud/php/insert.php',{
      formData:formData
    })
      .then(function (response) {
        console.log(response.data);
        navigate('/deiEvents');
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div>
     <nav>
        <Navdei/>
      </nav>
      <div className="dei-app-body">
        <div className="dei-app-body-main-content">
          <section className="dei-service-section">
            <div>
                <h2>Create Event</h2>
                <form onSubmit={handleSubmit}>
                  <table>
                    <tr>
                  <th><label>Event Name</label></th>
                  <td><input type="text" name="eventName" value={formData.eventName} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Event Description</label></th>
                  <td><input type="text" name="eventDescription" value={formData.eventDescription} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Event Organizer</label></th>
                  <td><input type="text" name="eventOrganizer" value={formData.eventOrganizer} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Event Location</label></th>
                  <td><input type="text" name="eventLocation" value={formData.eventLocation} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Registration Details</label></th>
                  <td><input type="text" name="registrationDetails" value={formData.registrationDetails} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Event Date</label></th>
                  <td><input type="date" name="eventDate" value={formData.eventDate} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <td colSpan="2" align ="right"><input type="submit" className="ip-button" value="Publish" /></td>
                  </tr>
                  </table>
                </form>
              </div>
              </section>
              </div>
              </div>
            </div>

  );

  

}

export default DeiCreateevents;
