import React, { useState } from 'react';
import './addissues.css'; // Make sure to import the CSS file
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddIssues = () => {
  const navigate = useNavigate();
  // const [inputs, setInputs] = useState([]);

  const [formData, setFormData] = useState({
    tableName: 'createissues',
    columns: {
      id:'',
      issuesName: '',
      Details: '',
      generateddate:'',
      status:'pending'
    }
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: value,
      },
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

  
    axios.post('https://mxs4224.uta.cloud/php/login.php',formData)
      .then(function (response) {
        console.log(response.data);
        // navigate('/issues');
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  

  return (
    <div className="admin-container">
      <h2>Create Issues</h2>
                <form onSubmit={handleSubmit}>
                  <table>
                    <tr>
                  <th><label>Issues Name</label></th>
                  <td><input type="text" name="issuesName" value={formData.issuesName} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Details</label></th>
                  <td><input type="text" name="Details" value={formData.Details} onChange={handleChange} /></td>
                  </tr>
                
            
             
                  <tr>
                  <th><label>Generated Date</label></th>
                  <td><input type="date" name="generateddate" value={formData.generateddate} onChange={handleChange} /></td>
                  </tr>
                  
                  <tr>
                  <th><label>Status</label></th>
                  <td><input type="text" name="status" value={formData.status} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    
                  <td colSpan="2" align ="right"><input type="submit" className="ip-button" value="Publish" /></td>
                  </tr>
                  </table>
                </form>

    </div>
  );
};

export default AddIssues;
