import React from 'react';
import './AboutUs.css';
import Navhome from './Navhome';

const AboutUs = () => {
  return (
    <div>
      <nav className="navBar">
   <Navhome/>
      </nav>

      <div className="aboutSection">
        <h1>About Us</h1>
        <p>We are a team of 5 energetic Graduate students from the University of Texas at Arlington, building the URM Connect Platform </p>
        <h2 style={{ textAlign: "center" }}>Our Team</h2>
      </div>

      <div className="row">
        <div className="column">
          <div className="card">
            <div className="container">
              <h2>Darshil Shah</h2>
              <p className="title">CEO & Founder</p>
              <p>Some text that describes me lorem ipsum ipsum lorem.</p>
              <p>darshil@example.com</p>
              <p><button className="button">Contact</button></p>
            </div>
          </div>
        </div>

        {/* Add other team members' cards here */}
      </div>
      <div className="row">
        <div className="column">
          <div className="card">
            <div className="container">
              <h2>Shivani Reddy</h2>
              <p className="title">Co-Founder</p>
              <p>Some text that describes me lorem ipsum ipsum lorem.</p>
              <p>shivani@example.com</p>
              <p><button className="button">Contact</button></p>
            </div>
          </div>
        </div>

        {/* Add other team members' cards here */}
      </div>
      <div className="row">
        <div className="column">
          <div className="card">
            <div className="container">
              <h2>Krutik Shah</h2>
              <p className="title">Founding Engineer</p>
              <p>Some text that describes me lorem ipsum ipsum lorem.</p>
              <p>krutik@example.com</p>
              <p><button className="button">Contact</button></p>
            </div>
          </div>
        </div>

        {/* Add other team members' cards here */}
      </div>
      <div className="row">
        <div className="column">
          <div className="card">
            <div className="container">
              <h2>Maaz Sheriff</h2>
              <p className="title">Technical Lead</p>
              <p>Some text that describes me lorem ipsum ipsum lorem.</p>
              <p>maaz@example.com</p>
              <p><button className="button">Contact</button></p>
            </div>
          </div>
        </div>

        {/* Add other team members' cards here */}
      </div>
      <div className="row">
        <div className="column">
          <div className="card">
            <div className="container">
              <h2>Manideep Shanigaram</h2>
              <p className="title">Lead Architect</p>
              <p>Some text that describes me lorem ipsum ipsum lorem.</p>
              <p>manideep@example.com</p>
              <p><button className="button">Contact</button></p>
            </div>
          </div>
        </div>

        {/* Add other team members' cards here */}
      </div>
    </div>
  );
}

export default AboutUs;