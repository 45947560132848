// SignupPage.js
import React, { useState } from 'react';
import './style_rec.css';
import Navrecruiter from './Navrecruiter';
import axios from "axios";
import { useNavigate } from "react-router-dom";
function SignupPage_rec() {
  const navigate = useNavigate();
  // const [inputs, setInputs] = useState([]);

  const [formData, setFormData] = useState({
    tableName: 'createjob',
    columns: {
      id: '',
      jobposition:'',
      recruitingagency: '',
      clientname: '',
      jobdescription:'',
      candidatepreferences:'',
      locationpreferences:'',
      
    }
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: value,
      },
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  
    axios.post('https://djs4331.uta.cloud/php/login.php', formData)
      .then(function (response) {
        console.log(response.data);
        navigate('/');
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  

  return (
    <div><nav>
    <Navrecruiter/>
  </nav>

    <form className="aRegForm_aca" onSubmit={handleSubmit}>
      
    <div className="academia_registration">
      <h1>Recruiter Registration</h1>

      <table>
                    <tr>
                  <th><label>Recruiting Agency Name</label></th>
                  <td><input type="text" name="recruitingagency" value={formData.recruitingagency} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Client Name</label></th>
                  <td><input type="text" name="clientname" value={formData.clientname} onChange={handleChange} /></td>
                  </tr>
                
            
             
                  <tr>
                  <th><label>Position</label></th>
                  <td><input type="text" name="jobposition" value={formData.jobposition} onChange={handleChange} /></td>
                  </tr>
                  
                  <tr>
                  <th><label>Candidate Prefrence</label></th>
                  <td><input type="text" name="candidatepreferences" value={formData.candidatepreferences} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Location Preference</label></th>
                  <td><input type="text" name="locationpreferences" value={formData.locationpreferences} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Job Description</label></th>
                  <td><input type="text" name="jobdescription" value={formData.jobdescription} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    
                  <td colSpan="2" align ="right"><input type="submit" className="ip-button" value="Publish" /></td>
                  </tr>
                  </table>
    </div>
  </form>
  </div>
  );
}

export default SignupPage_rec;