import React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './main_stylesheet.css'; // Make sure to import the CSS file if it exists.
import './createInitiative.css';
import Navdei from './Navdei';
function DeiCreateinitiative() {
  const navigate = useNavigate();


  // State for the eventName entered by the user
  const [eventNameToDelete, setEventNameToDelete] = useState('');

  const [formData, setFormData] = useState({
    tableName: 'deiInitiative',
    columns: {
      initiativeId: '',
      deiRole: '',
      initiativeDetails: '',
      generatedDate: ''
    }
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: value,
      },
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  
    axios.post('https://djs4331.uta.cloud/php/insert.php',{
      formData:formData
    })
      .then(function (response) {
        console.log(response.data);
        navigate('/deiInitiatives');
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div>
     <nav>
        <Navdei/>
      </nav>
      <div className="container pt-6">
        <div className="row justify-content-center">
          <div className="col-6 col-sm-12 col-md-8 col-lg-8">
            <div className="p-1">
              <div className="academia-jobs">
                <h2>Create Initiative</h2>
                <form onSubmit={handleSubmit}>
                  <table>
                    <tr>
                  <th><label>DEI Role</label></th>
                  <td><input type="text" name="deiRole" value={formData.deiRole} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Initiative Details</label></th>
                  <td><input type="text" name="initiativeDetails" value={formData.initiativeDetails} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Generated Date</label></th>
                  <td><input type="date" name="generatedDate" value={formData.generatedDate} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <td colSpan="2" align ="right"><input type="submit" className="ip-button" value="Publish" /></td>
                  </tr>
                  </table>
                </form>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      </div>

  );

  

}

export default DeiCreateinitiative;
