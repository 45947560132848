import React, { useState, useEffect } from 'react';
import './taskBarCan.css';
import './jobListCan.css';
import './listCardsCan.css';
import './jobDetailCan.css';
import './secondPartCan.css';
import './profileCan.css';
import Navcandidate from './Navcandidate';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CandidateProfile = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    demographic: '',
    field_of_study: '',
    edu_background: '',
    research_exp: '',
    interest: '',
    location: '',
    upload_resume: '',
    upload_cover_letter: '',
  });

  useEffect(() => {
    // Fetch data from the API and populate the form fields
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Replace 'candidate@gmail.com' with the actual logged-in user's email
      const userEmail = 'candidate@gmail.com';
      const response = await axios.get(`https://djs4331.uta.cloud/php/fetch_candidate.php?email=${userEmail}`);
      if (response.status === 200) {
        const data = response.data[0]; // Assuming only one record is returned from the API
        // Populate the form fields with the fetched data
        setFormData({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password,
          demographic: data.demographic,
          field_of_study: data.field_of_study,
          edu_background: data.edu_background,
          research_exp: data.research_exp,
          interest: data.interest,
          location: data.location,
          upload_resume: data.upload_resume,
          upload_cover_letter: data.upload_cover_letter,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you want to update the candidate profile with the new data
    axios.post('https://djs4331.uta.cloud/php/candidate_profile.php', formData)
      .then(function (response) {
        console.log(response.data);
        // Handle success or navigate to the next page
      })
      .catch(function (error) {
        console.error(error);
        // Handle error
      });
  };

  return (
    <div className="can-app">
      <nav>
        <Navcandidate />
      </nav>
      <section className="can-service-section-form">
        <form className="can-candidate-from" onSubmit={handleSubmit}>
          <div className="can-card">
            <label className="can-lableEdit" htmlFor="first_name">
              First Name:
            </label>
            <input
              type="text1"
              id="first_name"
              name="first_name"
              placeholder="First Name"
              value={formData.first_name}
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="last_name">
              Last Name:
            </label>
            <input
              type="text1"
              id="last_name"
              name="last_name"
              placeholder="Last Name"
              value={formData.last_name}
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="password">
              Password:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Edit Password"
              value={formData.password}
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="demographic">
              Demographic Information:
            </label>
            <textarea
              id="demographic"
              name="demographic"
              placeholder="Demographic Information"
              rows="3"
              value={formData.demographic}
              onChange={handleChange}
            ></textarea>

            <label className="can-lableEdit" htmlFor="field_of_study">
              Field of Study:
            </label>
            <input
              type="text1"
              id="field_of_study"
              name="field_of_study"
              placeholder="Field of Study"
              value={formData.field_of_study}
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="edu_background">
              Educational Background:
            </label>
            <textarea
              id="edu_background"
              name="edu_background"
              rows="3"
              placeholder="Educational Background"
              value={formData.edu_background}
              onChange={handleChange}
            ></textarea>

            <label className="can-lableEdit" htmlFor="research_exp">
              Research Experience:
            </label>
            <textarea
              id="research_exp"
              name="research_exp"
              rows="3"
              placeholder="Research Experience"
              value={formData.research_exp}
              onChange={handleChange}
            ></textarea>

            <label className="can-lableEdit" htmlFor="interest">
              Interest:
            </label>
            <textarea
              type="text1"
              id="interest"
              name="interest"
              placeholder="Interest"
              value={formData.interest}
              onChange={handleChange}
            ></textarea>

            <label className="can-lableEdit" htmlFor="location">
              Location/Preferences:
            </label>
            <textarea
              type="text1"
              id="location"
              name="location"
              placeholder="Location/Preference"
              value={formData.location}
              onChange={handleChange}
            ></textarea>

            <label className="can-lableEdit" htmlFor="upload_resume">
              Upload resume link:
            </label>
            <input
              type="text1"
              id="upload_resume"
              name="upload_resume"
              placeholder="Resume Link"
              value={formData.upload_resume}
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="upload_cover_letter">
              Upload Cover letter link:
            </label>
            <input
              type="text1"
              id="upload_cover_letter"
              name="upload_cover_letter"
              placeholder="Cover Letter Link"
              value={formData.upload_cover_letter}
              onChange={handleChange}
            />

            <br />
            <button type="submit">Save</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default CandidateProfile;

