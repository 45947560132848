import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Login = () => {
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send login data to the backend for authentication
    axios
      .post('https://mxs4224.uta.cloud/php/login1.php', userData)
      .then((response) => {
        // const { success, role, id } = response.data;
        const { data } = response; 
        if (data.success === 1) {
          // Store login data in localStorage
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('userRole', data.role);
          localStorage.setItem('uid',data.id);
          sessionStorage.setItem('loggedIn', 'true');

          sessionStorage.setItem('userRole', data.role);

          sessionStorage.setItem('uid',data.id);

          // Redirect to the appropriate dashboard based on the user role
          switch (data.role) {
            case 'admin':
              navigate('/ADashboard');
              break;
            case 'deiofficer':
              navigate('/MainDashboard');
              break;
            case 'recruiter':
              navigate('/Dashboard_rec');
              break;
            case 'academia':
              navigate('/Dashboard_aca');
              break;
            case 'candidate':
              navigate('/CandidateDashboard');
              break;
            default:
              // Handle unknown role or error case
          }
        } else {
          // Handle login failure, e.g., show an error message
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle login failure, e.g., show an error message
      });
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={userData.email}
          onChange={handleChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={userData.password}
          onChange={handleChange}
        />
        <button type="submit">Login</button>
      </form>
      <button type="submit">
        <Link to="/resetpassword">Reset Password</Link>
      </button>
    </div>
  );
};

export default Login;
