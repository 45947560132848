import React, { useState } from 'react';
import './deiedit.css'; // Make sure to import the CSS file

const Editprofiledei = () => {
  const [formData, setFormData] = useState({
    uname: 'user123',
    email: 'darshil@gmail.com',
    pwd: '',
    cpwd: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <label htmlFor="uname">Username</label>
        <input
          type="text"
          id="uname"
          name="uname"
          value={formData.uname}
          onChange={handleChange}
        />

        <label htmlFor="email">Email</label>
        <input
          type="text"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />

        <label htmlFor="pwd">Password</label>
        <input
          type="text"
          id="pwd"
          name="pwd"
          value={formData.pwd}
          onChange={handleChange}
        />

        <label htmlFor="cpwd">Confirm Password</label>
        <input
          type="text"
          id="cpwd"
          name="cpwd"
          value={formData.cpwd}
          onChange={handleChange}
        />

        <input type="submit" value="Submit" />
      </form>
    </div>
  );
};

export default Editprofiledei;
