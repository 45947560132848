import React from "react";
import './navhome.css';
import { Link } from "react-router-dom";
// import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';

// import AboutUs from "./AboutUs";
// import ContactUs from "./ContactUs";
// import Services from "./Services";
// import Home from "./Home";

function Navhome() {
  return (
    <div>
      <ul className="navList">
        <li><Link to="/Home">Home</Link></li>
        <li><Link to="/services">Services</Link></li>
        <li><a href = 'http://kxs3150.uta.cloud/'>Blog</a></li>
        <li><Link to="/contactUs">Contact Us</Link></li>
        <li><Link to="/AboutUs">About Us</Link></li>
        <li><Link to="/Login">Login</Link></li>
        <li><Link to="/register">Register</Link></li>
        </ul>
    </div>
        
        );
        }

export default Navhome;
