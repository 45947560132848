import React, { Component } from 'react';
import './taskBarCan.css';
import './jobListCan.css';
import './listCardsCan.css';
import './jobDetailCan.css';
import './secondPartCan.css';
import './profileCan.css';
import Navcandidate from './Navcandidate';
import { Link } from 'react-router-dom';

class CandidateDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobListings: [],
      selectedJob: null,
      loading: true,
      error: null,
    };
  }

  async componentDidMount() {
    try {
      const response = await fetch('https://djs4331.uta.cloud/php/job_list.php');
      if (!response.ok) {
        throw new Error('Failed to fetch job listings.');
      }
      const data = await response.json();
      this.setState({ jobListings: data.data, loading: false });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }

  handleJobClick = (index) => {
    this.setState({ selectedJob: index });
  };

  render() {
    const { jobListings, loading, error, selectedJob } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    return (
      <div className="can-app">
        <nav>
          <Navcandidate />
        </nav>
        <div className="can-app-body">
          <div className="can-app-body-main-content">
            <section className="can-service-section">
              <div className="can-container">
                <div className="can-list">
                  <h1 className="can-headline-list">Job Listing</h1>
                  <div className="can-ag-courses_box">
                    <div className="can-ag-format-container">
                      <ul className="can-item-list">
                        {jobListings && jobListings.length > 0 ? (
                          jobListings.map((job, index) => (
                            <li className="can-item" key={index}>
                              <div
                                className="can-ag-courses_item"
                                onClick={() => this.handleJobClick(index)}
                              >
                                <Link to={{ pathname: "/apply_form", state: { selectedJob: job } }}></Link>
                                <a href="#" className="can-ag-courses-item_link">
                                  <div className="can-ag-courses-item_bg"></div>
                                  <div className="can-ag-courses-item_title">{job.jobPosition}</div>
                                  <div className="can-ag-courses-item_date-box">
                                    <div className="can-ag-courses-item_location">
                                      {job.locationPreferences}
                                    </div>
                                  </div>
                                  <div className="can-ag-courses-item_date-box">
                                    Job Description:
                                    <span className="can-ag-courses-item_date">
                                      {job.jobDescription}
                                    </span>
                                  </div>
                                  <div className="can-ag-courses-item_date-box">
                                    Candidate Preferences:
                                    <span className="can-ag-courses-item_date">
                                      {job.candidatePreferences}
                                    </span>
                                  </div>
                                  <div className="can-ag-courses-item_date-box">
                                    Recruiting Agency:
                                    <span className="can-ag-courses-item_date">
                                      {job.recruitingAgency}
                                    </span>
                                  </div>
                                  <div className="can-ag-courses-item_date-box">
                                    Client Name:
                                    <span className="can-ag-courses-item_date">{job.clientName}</span>
                                  </div>
                                </a>
                              </div>
                            </li>
                          ))
                        ) : (
                          <li>No job listings available.</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="can-details">
                  {selectedJob !== null && (
                    <div className="can-details">
                      <h1 className="can-headline">
                        {jobListings[selectedJob].jobPosition}
                      </h1>
                      <div className="can-article-meta">
                        <p className="can-begining_date">Posted by :- {jobListings[selectedJob].recruitingAgency}</p>
                        <p className="can-article-tags">Location :- {jobListings[selectedJob].locationPreferences}</p>
                      </div>
                      <h2 className="can-subhead can-job-detail-heading">Job Description:</h2>
                      <span className="can-ag-courses-item_date">
                        {jobListings[selectedJob].jobDescription}
                      </span>
                      <div className="can-apply-chat-buttom">
                        <button type="submit">
                          <Link to="/apply_form">Apply</Link>
                        </button>

                        <button type="submit">
                          <a href="#">Chat</a>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default CandidateDashboard;

// import React, { Component } from 'react';
// import './taskBarCan.css';
// import './jobListCan.css';
// import './listCardsCan.css';
// import './jobDetailCan.css';
// import './secondPartCan.css';
// import './profileCan.css';
// import Navcandidate from './Navcandidate';
// import { Link } from 'react-router-dom';

// class Candidate_dynamic_job_list extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       jobListings: [],
//       selectedJob: null,
//       loading: true,
//       error: null,
//       appliedJobs: [], // Array to store the applied job titles
//     };
//   }

//   async componentDidMount() {
//     try {
//       const response = await fetch('http://localhost/urm_backend/job_list.php');
//       if (!response.ok) {
//         throw new Error('Failed to fetch job listings.');
//       }
//       const data = await response.json();
//       this.setState({ jobListings: data.data, loading: false });

//       // Fetch applied job titles and update the state
//       await this.fetchAppliedJobs();
//     } catch (error) {
//       this.setState({ error: error.message, loading: false });
//     }
//   }

//   fetchAppliedJobs = async () => {
//     try {
//       const response = await fetch('http://localhost/urm_backend/applied_jobs.php');
//       if (!response.ok) {
//         throw new Error('Failed to fetch applied job titles.');
//       }
//       const data = await response.json();
//       // Extract the job titles from the data and update the state
//       const appliedJobTitles = data.data.map((job) => job.job_title);
//       this.setState({ appliedJobs: appliedJobTitles });
//     } catch (error) {
//       console.error('Error fetching applied job titles:', error);
//     }
//   };

//   handleJobClick = (index) => {
//     const { jobListings, appliedJobs } = this.state;
//     const selectedJobTitle = jobListings[index].jobPosition;

//     if (appliedJobs.includes(selectedJobTitle)) {
//       // Job title already applied, do nothing
//       return;
//     }

//     this.setState((prevState) => ({
//       selectedJob: index,
//       appliedJobs: [...prevState.appliedJobs, selectedJobTitle], // Add the job title to the appliedJobs array
//     }));
//   };

//   render() {
//     const { jobListings, loading, error, selectedJob, appliedJobs } = this.state;

//     if (loading) {
//       return <div>Loading...</div>;
//     }

//     if (error) {
//       return <div>Error: {error}</div>;
//     }

//     return (
//       <div className="can-app">
//         <nav>
//           <Navcandidate />
//         </nav>
//         <div className="can-app-body">
//           <div className="can-app-body-main-content">
//             <section className="can-service-section">
//               <div className="can-container">
//                 <div className="can-list">
//                   <h1 className="can-headline-list">Job Listing</h1>
//                   <div className="can-ag-courses_box">
//                     <div className="can-ag-format-container">
//                       <ul className="can-item-list">
//                         {jobListings && jobListings.length > 0 ? (
//                           jobListings.map((job, index) => (
//                             <li className="can-item" key={index}>
//                               <div
//                                 className="can-ag-courses_item"
//                                 onClick={() => this.handleJobClick(index)}
//                               >
//                                 <Link to={{ pathname: "/apply_form", state: { selectedJob: job } }}>
//                                   <a href="#" className="can-ag-courses-item_link">
//                                     <div className="can-ag-courses-item_bg"></div>
//                                     <div className="can-ag-courses-item_title">{job.jobPosition}</div>
//                                     <div className="can-ag-courses-item_date-box">
//                                       <div className="can-ag-courses-item_location">
//                                         {job.locationPreferences}
//                                       </div>
//                                     </div>
//                                     <div className="can-ag-courses-item_date-box">
//                                       Job Description:
//                                       <span className="can-ag-courses-item_date">
//                                         {job.jobDescription}
//                                       </span>
//                                     </div>
//                                     <div className="can-ag-courses-item_date-box">
//                                       Candidate Preferences:
//                                       <span className="can-ag-courses-item_date">
//                                         {job.candidatePreferences}
//                                       </span>
//                                     </div>
//                                     <div className="can-ag-courses-item_date-box">
//                                       Recruiting Agency:
//                                       <span className="can-ag-courses-item_date">
//                                         {job.recruitingAgency}
//                                       </span>
//                                     </div>
//                                     <div className="can-ag-courses-item_date-box">
//                                       Client Name:
//                                       <span className="can-ag-courses-item_date">
//                                         {job.clientName}
//                                       </span>
//                                     </div>
//                                   </a>
//                                 </Link>
//                               </div>
//                             </li>
//                           ))
//                         ) : (
//                           <li>No job listings available.</li>
//                         )}
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="can-details">
//                   {selectedJob !== null && (
//                     <div className="can-details">
//                       <h1 className="can-headline">
//                         {jobListings[selectedJob].jobPosition}
//                       </h1>
//                       <div className="can-article-meta">
//                         <p className="can-begining_date">Posted by :- {jobListings[selectedJob].recruitingAgency}</p>
//                         <p className="can-article-tags">Location :- {jobListings[selectedJob].locationPreferences}</p>
//                       </div>
//                       <h2 className="can-subhead can-job-detail-heading">Job Description:</h2>
//                       <span className="can-ag-courses-item_date">
//                         {jobListings[selectedJob].jobDescription}
//                       </span>
//                       <div className="can-apply-chat-buttom">
//                         <button type="submit">
//                           {appliedJobs.includes(jobListings[selectedJob].jobPosition)
//                             ? "Applied"
//                             : <Link to="/apply_form">Apply</Link>}
//                         </button>

//                         <button type="submit">
//                           <a href="#">Chat</a>
//                         </button>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </section>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default Candidate_dynamic_job_list;
