import './App.css';
import { Routes, Route} from 'react-router-dom';


import DashboardAca from './academia/Dashboard_aca';
import ProfileAca from './academia/Profile_aca';
import RecruitersAca from './academia/Recruiters_aca';
import SignupPageAca from './academia/SignupPage_aca';
import UpdateProfileAca from './academia/UpdateProfile_aca';


import MainDashboard from './dei/MainDashboard';
import Alldeijobs from './dei/Alldeijobs';
import Deicandidates from './dei/Deicandidates';
// import Deichats from './dei/Deichats';
import Deiprofile from './dei/Deiprofile';
import Editprofiledei from './dei/Editprofiledei';
import DeiCreateevents from './dei/DeiCreateevents';
import DeiEvents from './dei/DeiEvents';
import DeiCreateinitiative from './dei/DeiCreateinitiative';
import Deibookmarks from './dei/Deibookmarks';
import DeiInitiatives from './dei/DeiInitiatives';


import ADashboard from './admin/ADashboard';
import NewUser from './admin/NewUser';
import Issues from './admin/Issues';
import User from './admin/Users';
import Report from './admin/Report';
import JobPosted from './admin/JobPosted';


import CandidateApplied from './candidate/CandidateApplied';
import CandidateDashboard from './candidate/CandidateDashboard';
import CandidateProfile from './candidate/CandidateProfile';

import AboutUs from './homepage/AboutUs';
import ContactUs from './homepage/ContactUs';
import Services from './homepage/Services';
import Home from './homepage/Home';
import Login from './homepage/Login';
import ResetPassword from './homepage/ResetPassword';


import DashboardRec from './recruiter/Dashboard_rec';
import ProfileRec from './recruiter/Profile_rec';
import RecruitersRec from './recruiter/Recruiters_rec';
import SignupPageRec from './recruiter/SignupPage_rec';
import UpdateProfileRec from './recruiter/UpdateProfile_rec';

import AddIssues from './admin/AddIssues';
import Register from './homepage/Register';
import Register_candidate from './candidate/register_candidate';
import Adminprofile from './admin/Adminprofile';
import ApplyForm from './candidate/ApplyForm';
function App() {
  return (
    <>

 
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/aboutUS" element={<AboutUs />} />
    <Route path="/contactUS" element={<ContactUs />} />
    <Route path="/services" element={<Services />} />
    <Route path="/Login" element={<Login />} />
    <Route path="/resetpassword" element={<ResetPassword />} />

   
    <Route path="/dashboard_aca" element={<DashboardAca />} />
    <Route path="/profile_aca" element={<ProfileAca />} />
    <Route path="/recruiters_aca" element={<RecruitersAca />} />
    <Route path="/signup_aca" element={<SignupPageAca />} />
    <Route path="/updateProfile_aca" element={<UpdateProfileAca />} />

 
    <Route path="/dashboard_rec" element={<DashboardRec />} />
    <Route path="/profile_rec" element={<ProfileRec />} />
    <Route path="/recruiters_rec" element={<RecruitersRec />} />
    <Route path="/signupPage_rec" element={<SignupPageRec />} />
    <Route path="/updateProfile_rec" element={<UpdateProfileRec />} />

    <Route path="/mainDashboard" element={<MainDashboard />} />  
    <Route path="/alldeijobs" element={<Alldeijobs />} />
    <Route path="/deicandidates" element={<Deicandidates />} />
    {/* <Route path="/deichats" element={<Deichats />} /> */}
    <Route path="/deiEvents" element={<DeiEvents />} />
    <Route path="/deiprofile" element={<Deiprofile />} />
    <Route path="/editprofiledei" element={<Editprofiledei />} />
    <Route path="/deiCreateevents" element={<DeiCreateevents />} />
    <Route path="/deiCreateinitiative" element={<DeiCreateinitiative />} />
    <Route path="/deiInitiatives" element={<DeiInitiatives />} />
    <Route path="/deibookmarks" element={<Deibookmarks />} />

    <Route path="/adashboard" element={<ADashboard/>} />
    <Route path="/newUser" element={<NewUser/>} />
    <Route path="/issues" element={<Issues/>} />
    <Route path="/user" element={<User/>} />
    <Route path="/report" element={<Report/>} />
    <Route path="/jobPosted" element={<JobPosted />} />

  
    <Route path="/candidateApplied" element={<CandidateApplied/>} />
    <Route path="/candidateDashboard" element={<CandidateDashboard/>} />
  <Route path="/candidateProfile" element={<CandidateProfile/>} />
  <Route path="/generateissues" element={<AddIssues />} />
    {/* <Route path="/createinitiative" element={<Createinitiative />} /> */}
    <Route path="/home" element={<Home />} />
    <Route path="/register" element={<Register />} />
    <Route path="/register_candidate" element={<Register_candidate/>} />
    <Route path="/adminprofile" element={<Adminprofile/>} />
    <Route path="/apply_form" element={<ApplyForm/>} />
    </Routes>

    </>
  );
}

export default App;