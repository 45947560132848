import React, { useState, useEffect } from 'react';
import './taskBarCan.css';
import './jobListCan.css';
import './listCardsCan.css';
import './jobDetailCan.css';
import './secondPartCan.css';
import './profileCan.css';
import Navcandidate from './Navcandidate';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const RegisterCandidate = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  // Fetch data from the backend API endpoint
  useEffect(() => {
    axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=createissues')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const [formData, setFormData] = useState({
    tableName: 'candidate_profile',
    columns: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      demographic: '',
      field_of_study: '',
      edu_background: '',
      research_exp: '',
      upload_resume: null, // Initialize with null since it's a file (blob) type
      upload_cover_letter: null, // Initialize with null since it's a file (blob) type
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: value,
      },
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: files[0], // Store the first file from the selected files array
      },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Check if the required fields are provided
    if (!formData.tableName || !formData.columns) {
      console.error('Invalid request. Missing table name or column data.');
      return;
    }
  
    try {
      // Create a new FormData object
      const formDataToSend = new FormData();
  
      // Append other form fields to formData
      for (const key in formData.columns) {
        formDataToSend.append(key, formData.columns[key]);
      }
  
      // Append file uploads to formData
      if (formData.upload_resume) {
        formDataToSend.append('upload_resume', formData.upload_resume);
      }
  
      if (formData.upload_cover_letter) {
        formDataToSend.append('upload_cover_letter', formData.upload_cover_letter);
      }
  
      // Make the API request to the backend for candidate registration
      const response = await axios.post('https://djs4331.uta.cloud/php/candidate_apply.php', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Add the proper content type for file uploads
        },
      });
  
      // Check if the request was successful
      if (response.data.success) {
        console.log('Candidate registered successfully:', response.data.message);
        navigate('/');
      } else {
        console.error('Failed to register candidate:', response.data.message);
      }
    } catch (error) {
      console.error('Error occurred during API request:', error);
    }
  };
  
  return (
    <div className="can-app">
      <nav>
        <Navcandidate />
      </nav>
      <section className="can-service-section-form">
        <form className="can-candidate-from" onSubmit={handleSubmit} method="POST" enctype="multipart/form-data">
        {events.map(event => (
          <div key={event.id} className="can-card" >
          
            <label className="can-lableEdit" htmlFor="name">
              First Name:
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              placeholder="First Name"
              value={event.first_name}
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="name">
              Last Name:
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              placeholder="Last Name"
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="password">
              Password:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Edit Password"
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="demographic">
              Demographic Information:
            </label>
            <textarea
              id="demographic"
              name="demographic"
              placeholder="Demographic Information"
              rows="3"
              onChange={handleChange}
            ></textarea>

            <label className="can-lableEdit" htmlFor="field_of_study">
              Field of Study:
            </label>
            <input
              type="text"
              id="field_of_study"
              name="field_of_study"
              placeholder="Field of Study"
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="edu_background">
              Educational Background:
            </label>
            <textarea
              id="edu_background"
              name="edu_background"
              rows="3"
              placeholder="Educational Background"
              onChange={handleChange}
            ></textarea>

            <label className="can-lableEdit" htmlFor="research_exp">
              Research Experience:
            </label>
            <textarea
              id="research_exp"
              name="research_exp"
              rows="3"
              placeholder="Research Experience"
              onChange={handleChange}
            ></textarea>

            <div className="can-upload-file-info">
              <label htmlFor="upload_resume" className="can-file-upload-label">
                Choose Resume File
              </label>
              <input
                type="file"
                id="upload_resume"
                name="upload_resume"
                accept=".pdf"
                onChange={handleFileChange}
              />
            </div>

            <div className="can-upload-file-info">
              <label htmlFor="upload_cover_letter" className="can-file-upload-label">
                Choose Cover Letter File
              </label>
              <input
                type="file"
                id="upload_cover_letter"
                name="upload_cover_letter"
                accept=".pdf"
                onChange={handleFileChange}
              />
            </div>

            <button type="submit">Save</button>
            
          </div>
          ))}
        </form>
      </section>
    </div>
  );
};

export default RegisterCandidate;


// import React, { useState } from 'react';
// import axios from 'axios';

// const RegisterCandidate = () => {
//   const [formData, setFormData] = useState({
//     title: '',
//     file: null,
//   });

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     setFormData({
//       ...formData,
//       [name]: name === 'file' ? files[0] : value,
//     });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       // Create a new FormData object
//       const formDataToSend = new FormData();
//       formDataToSend.append('title', formData.title);
//       formDataToSend.append('file', formData.file);

//       // Make the API request to upload the file
//       const response = await axios.post('http://your-api-url/upload.php', formDataToSend, {
//         headers: {
//           'Content-Type': 'multipart/form-data', // Add the proper content type for file uploads
//         },
//       });

//       // Check if the request was successful
//       if (response.data.success) {
//         console.log('File uploaded successfully:', response.data.message);
//       } else {
//         console.error('Failed to upload file:', response.data.message);
//       }
//     } catch (error) {
//       console.error('Error occurred during file upload:', error);
//     }
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <label>Title</label>
//         <input type="text" name="title" value={formData.title} onChange={handleChange} />

//         <label>File Upload</label>
//         <input type="file" name="file" onChange={handleChange} />

//         <button type="submit">Upload</button>
//       </form>
//     </div>
//   );
// };

// export default RegisterCandidate;

