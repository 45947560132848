import React, { useState, useEffect  } from 'react';
import './taskBarCan.css';
import './jobListCan.css';
import './listCardsCan.css';
import './jobDetailCan.css';
import './secondPartCan.css';
import './profileCan.css';
import Navcandidate from './Navcandidate';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ApplyForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    tableName: 'apply_form',
    columns: {
      job_title:'',
      first_name: '',
      last_name: '',
      email: '',
      demographic: '',
      field_of_study: '',
      edu_background: '',
      research_exp: '',
    },
  });

  const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          columns: {
            ...formData.columns,
            [name]: value,
          },
        });
      };

      const handleSubmit = (event) => {
        event.preventDefault();
      
        axios.post('https://djs4331.uta.cloud/php/apply.php', formData)
          .then(function (response) {
            console.log(response.data);
            navigate('/CandidateApplied');
          })
          .catch(function (error) {
            console.error(error);
          });
      };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0], // Store the first file from the selected files array
    });
  };

  

  return (
    <div className="can-app">
      <nav>
        <Navcandidate />
      </nav>
      <section className="can-service-section-form">
        <form className="can-candidate-from" onSubmit={handleSubmit}>
          <div className="can-card">
          {/* <Divsection
            tableName="createjob"
            columns={["jobPosition", "recruitingAgency"]}
            primaryKey="id"
          /> */}
          <label className="can-lableEdit" htmlFor="name">
              Job Title:
            </label>
            <input
              type="text1"
              id="job_title"
              name="job_title"
              placeholder="Job Title"

              onChange={handleChange}
            />
            <label className="can-lableEdit" htmlFor="name">
              First Name:
            </label>
            <input
              type="text1"
              id="first_name"
              name="first_name"
              placeholder="First Name"
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="name">
              Last Name:
            </label>
            <input
              type="text1"
              id="last_name"
              name="last_name"
              placeholder="Last Name"
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="demographic">
              Demographic Information:
            </label>
            <textarea
              id="  "
              name="demographic"
              placeholder="Demographic Information"
              rows="3"
              onChange={handleChange}
            ></textarea>

            <label className="can-lableEdit" htmlFor="field_of_study">
              Field of Study:
            </label>
            <input
              type="text1"
              id="field_of_study"
              name="field_of_study"
              placeholder="Field of Study"
              onChange={handleChange}
            />

            <label className="can-lableEdit" htmlFor="edu_background">
              Educational Background:
            </label>
            <textarea
              id="edu_background"
              name="edu_background"
              rows="3"
              placeholder="Educational Background"
              onChange={handleChange}
            ></textarea>

            <label className="can-lableEdit" htmlFor="research_exp">
              Research Experience:
            </label>
            <textarea
              id="research_exp"
              name="research_exp"
              rows="3"
              placeholder="Research Experience"
              onChange={handleChange}
            >

            </textarea>
            <br/>

            <button type="submit">Submit</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default ApplyForm;

