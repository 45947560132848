import React from 'react';
import './ContactUs.css';
import Navhome from './Navhome';

const ContactUs = () => {
  return (
    <div>
      <nav className="navBar">
   <Navhome/>

    
      </nav>

      <section className="contactUsMainSection">
        <div className="textBox">
          <p className="text-heading">Contact Us</p>
        </div>

        <div className="contactUsForm">
          <form action="index.html">
            <label htmlFor="contactName">Name</label>
            <input type="text" id="contactName" placeholder="Enter your name" />

            <label htmlFor="contactEmail">Email</label>
            <input type="text" id="contactEmail" placeholder="Enter your email" />

            <label htmlFor="details">Details</label>
            <textarea id="details" placeholder="Enter details" style={{ height: "100px" }} />

            <input type="submit" value="Submit" />
          </form>
        </div>
      </section>

      {/* <footer>
        <div className="buttons social-media">
          <a href="#" className="fa fa-facebook"></a>
          <a href="#" className="fa fa-linkedin"></a>
          <a href="#" className="fa fa-youtube"></a>
        </div>
      </footer> */}
    </div>
  );
}

export default ContactUs;