import React, { useState, useEffect } from 'react';
import './deicandidates.css';
import Navdei from './Navdei';
import axios from "axios";

function formatDate(timestamp) {
  const dateObject = new Date(timestamp);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const Deibookmarks = () => {
  const [events, setEvents] = useState([]);

  // Fetch data from the backend API endpoint
  useEffect(() => {
    // Add the 'where' condition to fetch only bookmarked candidates
    axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=candidate_profile&where=isBookmarked=1')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const handleUnmark = (ID) => {
    // Prepare the data to be sent in the request body
    const data = {
      ID: ID,
      isBookmarked: 0, // Set isBookmarked to 0 to unmark the candidate
    };

    // Make a POST request to update the isBookmarked field in the candidateDetails table
    axios.post('https://djs4331.uta.cloud/php/updateBookmarkStatus.php', data)
      .then((response) => {
        if (response.data.success === 1) {
          // Success: Bookmark status updated successfully
          console.log('Candidate unmarked successfully.');
          // Update the state to remove the unmarked candidate from the UI
          setEvents(events.filter(event => event.ID !== ID));
        } else {
          // Bookmark status update failed
          console.error('Candidate unmarking failed.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="dei-app">
      <nav>
        <Navdei />
      </nav>
      <div className="dei-app-body">
        <div className="dei-app-body-main-content">
          <section className="dei-service-section">
            <div>
              <h2>Bookmarked Candidates</h2>
              <table>
                <tr>
                  <th>Candidate Name</th>
                  <th>Email</th>
                  <th>Demographic Info</th>
                  <th>Field Of Study</th>
                  <th>Educational Background</th>
                  <th>Research Experience</th>
                  <th>Resume Link</th>
                  <th>Personal Statement/CV Link</th>
                  <th>Actions</th>
                </tr>
                {events.map(event => (
                  <tr key={event.ID}>
                    <td>{event.first_name} {event.last_name}</td>
                    <td>{event.email}</td>
                    <td>{event.demographic}</td>
                    <td>{event.field_of_study}</td>
                    <td>{event.edu_background}</td>
                    <td>{event.research_exp}</td>
                    <td>{event.upload_resume}</td>
                    <td>{event.upload_cover_letter}</td>
                    <td>
                      {/* <button onClick={() => handleChat(event.ID)} style={{ marginRight: '10px' }}>Chat</button> */}
                      <button onClick={() => handleUnmark(event.ID)} style={{ marginLeft: '10px' }}>Unmark</button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Deibookmarks;
