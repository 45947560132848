import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css';
import Navhome from './Navhome';

const Services = () => {
  return (
    <div>
  <nav className="navBar">
   <Navhome/>

       
      </nav>
      <div className="servicesSection">
        <h1>Services</h1>
        <div className="servicesIntro">
          <p>The URMConnect Portal provides a variety of User Roles creating an entire Ecosystem of Job Searching. Candidates
            can apply for research positions and jobs at Academia and also Companies managed by Recruiters. All processes and listings
            on the website can be monitored or reported to the DEI officers who can ensure that the DEI principles are upheld.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="column">
          <div className="card">
            <div className="container">
              <h2>URM Candidate</h2>
              <p>Applicants looking for MS, PhD and PostGraduate roles</p>
              <p><button onClick={() => window.location.href='/login'} className="button">URM Overview</button></p>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="card">
            <div className="container">
              <h2>Academia</h2>
              <p>Academic institutions providing positions of research and Employment for Candidates</p>
              <p><button onClick={() => window.location.href='/login'} className="button">University Profile</button></p>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="card">
            <div className="container">
              <h2>Recruiter</h2>
              <p>Recruiting companies offering positions to graduating URM Candidates</p>
              <p><button onClick={() => window.location.href='/login'} className="button">Recruiting Dashboard</button></p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="column">
          <div className="card">
            <div className="container">
              <h2>DEI Officer</h2>
              <p>Diversity, Equity and Inclusion in all Job listings will be mediated by registered DEI Officers</p>
              <p><button onClick={() => window.location.href='/login'} className="button">DEI Officers</button></p>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="card">
            <div className="container">
              <h2>Admin</h2>
              <p>Though not a user role, all Data present on the website will be accessible to the Administrator</p>
              <p><button onClick={() => window.location.href='/login'} className="button">Admin (Top Secret)</button></p>
            </div>
          </div>
        </div>
      </div>

      <div className="filler">
      </div>
    </div>
  );
}

export default Services;