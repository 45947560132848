import React, { useState, useEffect } from 'react';
import './issues.css';
import Navadmin from './Navadmin';
import axios from "axios";

const JobPosted = () => {
  const [events, setEvents] = useState([]);

  // Fetch data from the backend API endpoint
  useEffect(() => {
    axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=createjob')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
 
  return (
    <div className="admin-app">
      <nav>
        <Navadmin/>
      </nav>
      <div className="admin-app-body">
        <div className="admin-app-body-main-content">
          <section className="admin-service-section">
            <div>
              <h2>Job Posted</h2>
              <table>
                <tr>
                  <th>Job Position</th>
                  <th>Recruiting Agency</th>
                  <th>Client Name</th>
                  <th>Job Description</th>
                  <th>Candidate Preference</th>
                  {/* <th>Location Preference</th> */}
                </tr>
                {events.map(event => (
                  <tr key={event.id}>
                    <td>{event.jobPosition}</td>
                    <td>{event.recruitingAgency}</td>
                    <td>{event.clientName}</td>
                    <td>{event.jobDescription}</td> 
                    <td>{event.candidatePreferences}</td> 
                    {/* <td>{event.locationPreferences}</td>                   */}
                  </tr>
                ))}
              </table>
          
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default JobPosted;
