import React, { useState, useEffect } from 'react';
import './issues.css';
import Navadmin from './Navadmin';
import { Link } from 'react-router-dom';
import axios from "axios";

const Issues = () => {
  const [events, setEvents] = useState([]);

  // Fetch data from the backend API endpoint
  useEffect(() => {
    axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=createissues')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  const handleDelete = (id) => {
    const primarykey = 'id';

    axios
      .delete(`https://djs4331.uta.cloud/php/delete.php?identifier=${id}&tableName=createissues&primaryKey=${primarykey}`)
      .then((response) => {
        // Check if the deletion was successful
        if (response.data.success === 1) {
          // Update the state to remove the deleted item from the UI
          setEvents(events.filter(event => event.id !== id));
        } else {
          // Handle deletion failure, e.g., show an error message
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message
        console.error(error);
      });
  };
  return (
    <div className="app">
      <nav>
        <Navadmin/>
      </nav>
      <div className="admin-app-body">
        <div className="admin-app-body-main-content">
          <section className="admin-service-section">
            <div>
              <h2>Issues</h2>
              <table>
                <tr>
                  <th>Issues</th>
                  <th>Details</th>
                  <th>Generated Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
                {events.map(event => (
                  <tr key={event.id}>
                    <td>{event.issuesname}</td>
                    <td>{event.details}</td>
                    <td>{event.generateddate}</td>
                    <td>{event.status}</td>
                    <td>                    
                      <td>
                      <button onClick={() => handleDelete(event.id)} style={{ marginRight: '0px' }}>Delete</button>
                  
                    </td>
                    <td>
                     
                      
                      <button onClick={() => handleDelete(event.id)} style={{ marginleft: '0px' }}>Edit</button>
                    </td>
                    </td>

                    
                  </tr>
                ))}
              </table>
              <table>
                <tr>
                  <td>
                    <button>
                      <Link to="/generateissues">Generate Issues</Link>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Issues;
