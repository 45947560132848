import React from 'react';
import './style_rec.css';
import { Link } from 'react-router-dom';
import Navrecruiter from './Navrecruiter';
function Profile_rec() {

    const headingStyle = {
        color: '#3B5D76',
      };

      const hrStyle = {
        width: '98%',
        marginLeft: '1px',
        textAlign: 'left',
      };

  return (
   <div>
    <nav>
        <Navrecruiter/>
      </nav>
   <h1 style={headingStyle}>University of Texas at Arlington</h1>
    <p className="profile-description">Welcome to the University's Home Page. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel metus eu purus fermentum dapibus a eu dui.</p>
   

    <hr style={hrStyle}  />
    <br />

     {/* <a className="grid-button" style="text-align: center; font-size: medium;" href="academia_update_profile.html">Want to edit your Academia profile ? Click Here</a> */}

    <br />
    <hr style={hrStyle} />
    <br />


    <div className="two-columns-grid">
        <div>
            <label for="myFile" className="dashboard-heading">
                All Faculty
            </label>
            <br/>
            <div className="secondgrid">
                <div>
                    <h2>Faculty Name </h2>
                    <p>Faculty Qualification </p>
                </div>
                <div className="button-container">
                    <label className="grid-button">Remove Faculty</label>
                </div>
            </div>
            
            <div className="secondgrid">
                <div>
                    <h2>Faculty Name </h2>
                    <p>Faculty Qualification </p>
                </div>
                <div className="button-container">
                    <label className="grid-button">Remove Faculty</label>
                </div>
            </div>
    
        </div>

        <div>
            <label for="myFile" className="dashboard-heading">
                All Researches
            </label>
    
            <div className="secondgrid">
                <div>
                    <h2>Research Title </h2>
                    <p>Research Description</p>
                </div>
            </div>
    
            <div className="secondgrid">
                <div>
                    <h2>Research Title </h2>
                    <p>Research Description</p>
                </div>
            </div>
        </div>
    
    </div>
   </div>
  );
}

export default Profile_rec;
