import React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './adminprofile.css'; 
import Navadmin from './Navadmin';


function Adminprofile() {
  const navigate = useNavigate();

  // Add a loading state to handle data fetching from backend
  const [loading, setLoading] = useState(true);

  const [events, setEvents] = useState([]);

  const [formData, setFormData] = useState({
    tableName: 'register',
    primaryKey: 'id',
    columns: {
      id: '',
      F_name: '',
      L_name: '',
      email: '',
      password: '',
      role: '',
      city: '',
      state: '',
      zipcode: '',
    
    },
  });

  // Fetch data from the backend API endpoint
  useEffect(() => {
    axios
      .get('https://djs4331.uta.cloud/php/fetch.php?tableName=register&id=${formData.columns.id}')
      .then((response) => {
        setEvents(response.data);
        setLoading(false); // Set loading to false after fetching data
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false); // Set loading to false even on error
      });
  }, []);

  useEffect(() => {
    // Set the initial state of formData.columns with data from the first event (assuming only one event exists)
    if (events.length > 0) {
      const firstEvent = events[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        columns: {
          ...prevFormData.columns,
          id: firstEvent.id,
          F_name: firstEvent.F_name,
          L_name: firstEvent.L_name,
          email: firstEvent.email,
          password: firstEvent.password,
          role: firstEvent.role,
          city: firstEvent.city,
          state: firstEvent.state,
          zipcode: firstEvent.zipcode,
        },
      }));
    }
  }, [events]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      columns: {
        ...prevFormData.columns,
        [name]: value,
      },
    }));
  
    // Update the corresponding event fields in the events state
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === formData.columns.id
          ? { ...event, [name]: value }
          : event
      )
    );
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Find the existing record using the id field in formData.columns
    const existingRecord = events.find((event) => event.id === formData.columns.id);
  
    // Prepare the data to be inserted or updated
    const dataToSubmit = {
      tableName: formData.tableName,
      primaryKey: formData.primaryKey,
      data: {
        columns: formData.columns,
      },
    };
  
    if (existingRecord) {
      // It's an update
      // Include the id in the columns object
      dataToSubmit.data.columns.id = existingRecord.id;
  
      axios
        .post('https://djs4331.uta.cloud/php/update.php', dataToSubmit)
        .then(function (response) {
          console.log(response.data);
          setEvents((prevEvents) => prevEvents.map(event => event.id === formData.columns.id ? formData.columns : event));
          navigate('/');
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      // It's an insert
      axios
        .post('https://djs4331.uta.cloud/php/insert.php', dataToSubmit)
        .then(function (response) {
          console.log(response.data);
          // If the insert was successful, update the events state with the new record
          setEvents((prevEvents) => [...prevEvents, formData.columns]);
          navigate('/');
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };
  return (
    <div>
      <nav>
        <Navadmin />
      </nav>
      <div className="dei-app-body">
        <div className="dei-app-body-main-content">
          <section className="dei-service-section">
            <h2>My Profile</h2>
            <form onSubmit={handleSubmit}>
              <div className="dei-profile-section">
                <h3>Personal Information</h3>
                <div className="dei-form-row">
                  <div className="dei-form-field">
                    <label>First Name</label>
                    <input type="text" name="F_name" value={formData.columns.F_name || ""} onChange={handleChange} />
                  </div>
                  <div className="dei-form-field">
                    <label>Last Name</label>
                    <input type="text" name="L_name" value={formData.columns.L_name || ""} onChange={handleChange} />
                  </div>
                </div>
                <div className="dei-form-row">
                  <div className="dei-form-field">
                    <label>Email</label>
                    <input type="text" name="email" value={formData.columns.email || ""} onChange={handleChange} />
                  </div>
                  <div className="dei-form-field">
                    <label>Password</label>
                    <input type="password" name="password" value={formData.columns.password || ""} onChange={handleChange} />
                  </div>
                </div>
                <div className="dei-form-row">
                  <div className="dei-form-field">
                    <label>Your Role</label>
                    <input type="text" name="role" value={formData.columns.role || ""} onChange={handleChange} />
                  </div>
                  <div className="dei-form-field">
                    <label>City</label>
                    <input type="text" name="city" value={formData.columns.city || ""} onChange={handleChange} />
                  </div>
                </div>
                <div className="dei-form-row">
                  <div className="dei-form-field">
                    <label>State</label>
                    <input type="text" name="state" value={formData.columns.state || ""} onChange={handleChange} />
                  </div>
                  <div className="dei-form-field">
                    <label>Zip Code</label>
                    <input type="text" name="zipcode" value={formData.columns.zipcode || ""} onChange={handleChange} />
                  </div>
                </div>
              </div>
              
              <div className="dei-form-row">
                <input type="submit" className="ip-button" value="Submit" />
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Adminprofile;