import React, { useState } from 'react';
 // Make sure to import the CSS file
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  // const [inputs, setInputs] = useState([]);

  const [formData, setFormData] = useState({
    tableName: 'register',
    columns: {
      id: '',
      F_name: '',
      L_name: '',
      city: '',
      state: '',
      zipcode: '',
      email:'',
      password: '',
      role:'admin'
    }
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: value,
      },
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  
    axios.post('https://djs4331.uta.cloud/php/registeremail.php', formData)
      .then(function (response) {
        console.log(response.data);
        navigate('/');
      })
      .catch(function (error) {
        console.error(error);
      });

  };
  

  return (
    <div className="container">
      <h2>Register</h2>
                <form onSubmit={handleSubmit}>
                  <table>
                  <tr>
                  <th><label>First Name</label></th>
                  <td><input type="text" name="F_name" value={formData.F_name} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Last Name</label></th>
                  <td><input type="text" name="L_name" value={formData.L_name} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>City</label></th>
                  <td><input type="text" name="city" value={formData.city} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>State</label></th>
                  <td><input type="text" name="state" value={formData.state} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                  <th><label>Zipcode</label></th>
                  <td><input type="text" name="zipcode" value={formData.zipcode} onChange={handleChange} /></td>
                  </tr>
                    <tr>
                  <th><label>Email</label></th>
                  <td><input type="text" name="email" value={formData.email} onChange={handleChange} /></td>
                  </tr>
                  
                
            
             
                  <tr>
                  <th><label>Password</label></th>
                  <td><input type="text" name="password" value={formData.password} onChange={handleChange} /></td>
                  </tr>
                  
                  <tr>
                  <th><label>Role</label></th>
                  <td><select name="role" value={formData.role} onChange={handleChange}>
          
          <option value="admin">Admin</option>
          <option value="candidate">Candidate</option>
          <option value="academia">Academia</option>
          <option value="recruiter">Recruiter</option>
          <option value="deiofficer">Dei Officer</option>
        </select></td>
                  </tr>
                  <tr>
                    
                  <td colSpan="2" align ="right"><input type="submit" className="ip-button" value="Register" /></td>
                  </tr>
                  </table>
                </form>

    </div>
  );
};

export default ResetPassword;
