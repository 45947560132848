import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import './main_stylesheet.css';

import Navdei from './Navdei';

function CardSection({ title, count}) {
    return (
      <div>
        <h2>{title}</h2>
        <div className="dei-cards">
          <div className="dei-card">
            <p>{count}</p>
          </div>
        </div>
        </div>
    );
  }



function MainDashboard() {

    const [jobPostedCount, setJobPostedCount] = useState(0);
    const [eventCount, setEventCount] = useState(0);
    const [urmCandidateCount, setUrmCandidateCount] = useState(0);
  

    const [data, setData] = useState([]);
    const [firstThreeEvents, setFirstThreeEvents] = useState([]);
  
    useEffect(() => {
      axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=deiEvents')
        .then((response) => {
          setData(response.data);
          setFirstThreeEvents(response.data.slice(0, 3));
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }, []);
    

    useEffect(() => {
        // Function to fetch job postings with DEI keywords count
        const fetchJobPostingsWithDEICriteriaCount = () => {
          const deiKeywords = ['diversity', 'equity', 'inclusion']; // Modify this array with your specific DEI keywords
          axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=createjob')
            .then((response) => {
              // Filter the data based on DEI keywords
              const filteredJobPostings = response.data.filter(item =>
                deiKeywords.some(keyword =>
                  item.jobDescription.toLowerCase().includes(keyword)
                )
              );
              setJobPostedCount(filteredJobPostings.length);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        };

         // Fetch job postings with DEI keywords count
        fetchJobPostingsWithDEICriteriaCount();    

        axios.get('https://djs4331.uta.cloud/php/getCount.php?tableName=deiEvents')
        .then((response) => {
          setEventCount(response.data.total);
        })
        .catch((error) => {
          console.error('Error:', error);
        });

        axios.get('https://djs4331.uta.cloud/php/getCount.php?tableName=candidate_profile&where=isBookmarked=1')
        .then((response) => {
          console.log('URM Candidate Count Response:', response.data);
          setUrmCandidateCount(response.data.total);
        })
        .catch((error) => {
          console.error('Error:', error);
        });}
        , []);

  return (
    <div className="dei-app">
    <nav>
      <Navdei/>
    </nav>
    <div className="dei-app-body">
        
        <div className="dei-app-body-main-content">
            <section className="dei-service-section">
                <h2>DEI Agenda</h2>
                <div className="dei-cards">
                    <div className="dei-card">
                        <p>A DEI (Diversity, Equity, and Inclusion) officer is responsible for promoting and implementing strategies that foster diversity, equity, and inclusion within an organization. The specific responsibilities of a DEI officer may vary depending on the organization, but they generally include: Strategy Development: Developing and implementing a comprehensive DEI strategy that aligns with the organization's goals and values</p>
                    </div>
                </div>
              </section>
              <section className="dei-service-section">
                <CardSection title="Job Posting that meet DEI criteria" count={jobPostedCount} />
                <CardSection title="URM candidates interested in DEI goals" count={urmCandidateCount} />
                <CardSection title="DEI initiatives/events launched" count={eventCount} />
                </section>
                       <section className="dei-service-section">
                        <div>
                        <h2>Events</h2>
                        <table>
                         <tr>
                           <th>Event Name</th>
                           <th>Event Description</th>
                           <th>Event Date</th>
                          </tr>
                            {firstThreeEvents.map(event => (
                                <tr key={event.eventId}>
                                <td>{event.eventName}</td>
                                <td>{event.eventDescription}</td>
                                <td>{event.eventDate}</td>
                           </tr>
                           ))}
                        </table>
                        <table>
                            <tr>
                              <td style={{textAlign: 'right'}}><Link style={{backgroundColor: 'white', textDecoration: 'none', color: 'blue'}} to="/deiEvents">Actions & More</Link></td>
                            </tr>
                        </table>
                        </div>
                    </section>
        </div>
      </div>
    </div>
  );
  }


export default MainDashboard;

