import React, { useState, useEffect } from 'react';
import './issues.css';
import Navadmin from './Navadmin';
import { Link } from 'react-router-dom';
import axios from "axios";

const Users = () => {
  const [events, setEvents] = useState([]);

  // Fetch data from the backend API endpoint
  useEffect(() => {
    axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=register')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  
  return (
    <div className="app">
      <nav>
        <Navadmin/>
      </nav>
      <div className="admin-app-body">
        <div className="admin-app-body-main-content">
          <section className="admin-service-section">
            <div>
              <h2>Users</h2>
              <table>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zipcode</th>
                  <th>role</th>
                </tr>
                {events.map(event => (
                  <tr key={event.id}>
                    <td>{event.F_name} {event.L_name}</td>
                    <td>{event.email}</td>
                    <td>{event.city}</td>
                    <td>{event.state}</td>
                    <td>{event.zipcode}</td>
                    <td>{event.role}</td>
                  </tr>
                ))}
              </table>
              
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Users;
