import React from 'react';
import './style_rec.css';
import { Link } from 'react-router-dom';
import Home from '../homepage/Home';

function Navrecruiter() {
  return (
    <header className='Nav_rec'>
      <ul>
  
        <li><Link to="/signupPage_rec">ADD Job</Link></li>
        <li><Link to="/dashboard_rec">Dashboard</Link></li>
        {/*<li><Link to="/profile_rec">Profile</Link></li>
        <li><Link to="/updateProfile_rec">UpdateProfile</Link></li>*/}
        <li><a href="http://kxs3150.uta.cloud/">Blog</a></li>
        <li><Link to="/Home">Logout</Link></li>
        
      </ul>
    </header>
  );
}

export default Navrecruiter;
