import React, { useState, useEffect } from 'react';
import './issues.css';
import Navadmin from './Navadmin';
import { Link } from 'react-router-dom';
import axios from "axios";

const NewUser = () => {
  const [events, setEvents] = useState([]);

  // Fetch data from the backend API endpoint
  useEffect(() => {
    axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=register')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  const handleDelete = (id) => {
    const primarykey = 'id';

    axios
      .delete(`https://djs4331.uta.cloud/php/update.php?identifier=${id}&tableName=createissues&primaryKey=${primarykey}`)
      .then((response) => {
        // Check if the deletion was successful
        if (response.data.success === 1) {
          // Update the state to remove the deleted item from the UI
          setEvents(events.filter(event => event.id !== id));
        } else {
          // Handle deletion failure, e.g., show an error message
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message
        console.error(error);
      });
  };
  return (
    <div className="app">
      <nav>
        <Navadmin/>
      </nav>
      <div className="admin-app-body">
        <div className="admin-app-body-main-content">
          <section className="admin-service-section">
            <div>
              <h2>New User</h2>
              <table>
              <tr>
                  <th>Name</th>
               
                  <th>City</th>
                  <th>State</th>
                  <th>Zipcode</th>
                  <th>role</th>
                  <th>Action</th>
                </tr>
                {events.map(event => (
                  <tr key={event.id}>
                    <td>{event.F_name} {event.L_name}</td>
                    {/* <td>{event.email}</td> */}
                    <td>{event.city}</td>
                    <td>{event.state}</td>
                    <td>{event.zipcode}</td>
                    <td>{event.role}</td>
                  
                    <td>                    
                      <td>
                      <button onClick={() => handleDelete(event.id)} style={{ marginRight: '0px' }}>Approve</button>
                  
                    </td>
                    <td>
                     
                      
                      <button onClick={() => handleDelete(event.id)} style={{ marginleft: '0px' }}>Decline</button>
                    </td>
                    <td>
                     
                      
                     <button onClick={() => handleDelete(event.id)} style={{ marginleft: '0px' }}>Chat</button>
                   </td>
                    </td>

                    
                  </tr>
                ))}
              </table>
           
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
