import React from "react";
import './style_aca.css';
import Navacademia from "./Navacademia";

import { useState,useEffect } from 'react';
import axios from 'axios';

function Dashboard_aca() {


    //fetching recruiters
    const userId = sessionStorage.getItem('uid');
    const [recruiterData, setRecruiterData] = useState([]);
    useEffect(() => {
        // Fetch faculty data from the PHP API
        axios.get(`https://mxs4224.uta.cloud/php/getAllRecruiters.php`)
          .then((response) => {
            setRecruiterData(response.data);
          })
          .catch((error) => {
            console.error('Error fetching faculty data:', error);
          });
        }, []);



    //fetching Jobs
    const [jobsData, setJobsData] = useState([]);
    useEffect(() => {
        // Fetch faculty data from the PHP API
        axios.get(`https://mxs4224.uta.cloud/php/getAcademiaJobs.php?uid=${userId}`)
          .then((response) => {
            setJobsData(response.data);
            // alert(response.data);
            // console.log(response.data);
          })
          .catch((error) => {
            console.error('Error fetching faculty data:', error);
          });
        }, []);


        //getAcademiaJobAppliedCandidates

        const [appiliedCandidateData, setAppiliedCandidateData] = useState([]);
        useEffect(() => {
            // Fetch faculty data from the PHP API
            axios.get(`https://mxs4224.uta.cloud/php/getAcademiaJobAppliedCandidates.php?uid=${userId}`)
              .then((response) => {
                setAppiliedCandidateData(response.data);
              })
              .catch((error) => {
                console.error('Error fetching faculty data:', error);
              });
            }, []);


            const [showInfo, setShowInfo] = useState(false);

  // Function to toggle the visibility of candidate information
  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };



  //for accept and reject 
  const [status, setStatus] = useState('');

  const handleAccept = (candidateId) => {
    updateStatus(candidateId, 'Accepted');
  };

  const handleReject = (candidateId) => {
    updateStatus(candidateId, 'Rejected');
  };
  const updateStatus = (candidateId, newStatus) => {
    const apiUrl = 'https://mxs4224.uta.cloud/php/acceptOrRejectApplication.php';
  

    let fData = new FormData();
  
    fData.append('job_id',candidateId);
    fData.append('status',newStatus);
  
    axios.post(apiUrl, fData)
    .then(response => {
      // alert(response.data);
      // window.location.reload();
    })
    .catch(error => {
      console.error('Status update failed', error);
    });
  };




    return (
   
        <div>
             <nav>
        <Navacademia/>
      </nav>

        
        <br/>
        
      

            
            <br/>
            <hr/>
            <br/>
        
             <div className="single-div">
                 <label for="myFile" className="dashboard-heading">
                    My Job Openings
                </label>

                <div className="single-div">
  {jobsData && jobsData.length > 0 ? (
    jobsData.map((job) => (
      <div key={job.id} className="secondgrid">
        <div>
    <h2>{job.jobPosition}</h2>
    <h4>{job.jobDescription}</h4>
 
</div>
<div class="job-preferences">
  <div class="location-preferences">
    <h3>Location Preferences:</h3>
    <p>{job.locationPreferences}</p>
  </div>
  <div class="candidate-preferences">
    <h3>Candidate Preferences:</h3>
    <p>{job.candidatePreferences}</p>
  </div>
</div>



      </div>
    ))
  ) : (
    <div  className="secondgrid">No Jobs Open in this Academia.</div>
  )}
</div> 
{/*                 
                 <div className="secondgrid">
                    <div>
                        <h2>Research Assistant</h2>
                        <p> Description of the research information</p>
                    </div>
                    <div className="button-container">
                        <label className="grid-button">Remove Opening</label>
                    </div>
                </div> 
           */}
            </div>
      
            <br/>
            <hr/>
            <br/>
        
            
         
        
            <div className="single-div">
                 <label for="myFile" className="dashboard-heading">
                    Applied Candidates
                </label>

                <div className="single-div">
  {appiliedCandidateData && appiliedCandidateData.length > 0 ? (
    appiliedCandidateData.map((can) => (


<div key={can.job_id} className="secondgrid">
      <div className="candidate-details">
        <h2>{can.last_name}, {can.first_name}</h2>
        <h4>Applied for: {can.jobPosition}</h4>
        <button className="view-info-button" onClick={toggleInfo}>
          {showInfo ? 'Hide Candidate Info' : 'View Candidate Info'}
        </button>
        {showInfo && (
         <div className="info-section">
         <div className="candidate-details">
           <h2>{can.last_name}, {can.first_name}</h2>
           <h4>Email: {can.email}</h4>
           <p>Demographic: {can.demographic}</p>
           <p>Field of Study: {can.field_of_study}</p>
           <p>Research Experience: {can.research_exp}</p>
           <p>Interests: {can.interest}</p>
         </div>
       </div>
       
        )}
      </div>
      <div >
      <button className="grid-button" onClick={() => handleAccept(can.job_id)}>Accept</button>
        <button className="grid-button" onClick={() => handleReject(can.job_id)}>Reject</button>
        <button className="grid-button">Chat</button>
                    </div>
    </div>
    ))
  ) : (
    <div  className="secondgrid">Candidates did not apply to the Jobs.</div>
  )}
</div> 


                {/* <div className="secondgrid">
                    <div>
                        <h2>Joe, Smith</h2>
                        <h2>Web Development</h2>
                        <p>Joe Smith experience and skils </p>
                    </div>
                    <div className="button-container">
                        <button className="grid-button">Accept</button>
                        <label className="grid-button">Reject</label>
                        <label className="grid-button">Chat</label>
                    </div>
                </div> */}
        
        
            </div>

            <br/>
            <hr/>
            <br/>
            <div  className="single-div"> 
                <label for="myFile" className="dashboard-heading">
                     Recruiters
                </label>

 <div className="single-div">
  {recruiterData && recruiterData.length > 0 ? (
    recruiterData.map((rec) => (
      <div key={rec.recruiter_id} className="secondgrid">
        <div>
          <h2>{rec.recruiter_name}</h2>
          <h4>{rec.recruiter_address}</h4>
        </div>
        <div>
        <button className="single-button">Chat</button>
        </div>
      </div>
    ))
  ) : (
    <div  className="secondgrid">No Recruites are avaialble.</div>
  )}
</div> 
        
                {/* <div className="secondgrid">
                    <div>
                        <h2>Erode Pvt Limited</h2>
                        <h4>Austin, Texas </h4>
                    </div>
                    <div className="button-container">
                        <label className="grid-button">Remove Connection</label>
                        <label className="grid-button">Chat</label>
                    </div>
                </div>  */}
        
        
            </div> 




            



        
        </div>
          );

}

export default Dashboard_aca;


