import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './dashboardAdmin.css';
import chart1 from './images/chart1.png';
import chart2 from './images/chart2.png';
import chart3 from './images/chart3.png';
import Navadmin from './Navadmin';
import { Link } from 'react-router-dom';

const ADashboard = () => {
  const [totalJobPosts, setTotalJobPosts] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalIssues, setTotalIssues] = useState(0);
  const [events, setEvents] = useState([]);
  const [canevents, setCanEvents] = useState([]);
  const [matchevents, setMatchEvents] = useState([]);

  useEffect(() => {

    // Fetch the total number of job posts from the backend API
    axios.get('https://mxs4224.uta.cloud/php/fetchjob.php?tableName=createjob')
    .then((response) => {
      setTotalJobPosts(response.data.totalJobPosts);
    })
    .catch((error) => {
      console.error('Error fetching job posts:', error);
    });

    axios.get('https://mxs4224.uta.cloud/php/fetchuser.php?tableName=register')
      .then((response) => {
        setTotalUsers(response.data.totalUsers);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    axios.get('https://mxs4224.uta.cloud/php/fetchissues.php?tableName=createissues')
      .then((response) => {
        setTotalIssues(response.data.totalIssues);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    axios.get('https://mxs4224.uta.cloud/php/fetch.php?tableName=createjob')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    axios.get('https://mxs4224.uta.cloud/php/fetch.php?tableName=register')
      .then((response) => {
        setCanEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    
    axios.get(`https://mxs4224.uta.cloud/php/fetch.php?tableName=candidate_profile`)
      .then((response) => {
        // alert(response.data);
        console.log(response.data)
        setMatchEvents(response.data);
        console.log(matchevents.length)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  

  return (
    <div className="admin-app">
      <nav>
        <Navadmin />
      </nav>
      <div className="admin-app-body">
        <div className="admin-app-body-main-content">
          <section className="admin-service-section">
            <h2>Overview</h2>
            <div className="admin-cards">
              <div className="admin-card">
                <h3>Total Users</h3>
                <p>{totalUsers}</p>
              </div>
              <div className="admin-card">
                <h3>Job Posted</h3>
                <p>{totalJobPosts}</p>
              </div>
              <div className="admin-card">
                <h3>Issues Resolve</h3>
                <p>{totalIssues}</p>
              </div>
            </div>
          </section>
          <section className="admin-service-section">
            <h2>Reports</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <table>
                <tr>
                  <th>Recruiter Report</th>
                  <th>Academia Report</th>
                  <th>DEI Report</th>
                </tr>
                <tr>
                  <td>
                    <img src={chart1} alt="Recruiter Report" />
                  </td>
                  <td>
                    <img src={chart2} alt="Academia Report" />
                  </td>
                  <td>
                    <img src={chart3} alt="DEI Report" />
                  </td>
                </tr>
              </table>
            </div>
          </section>
          <section className="admin-service-section">
            <div>
              <h2>Candidate Details</h2>
              <table>
                <tr>
                  <th>Candidate Name</th>
                  <th>Field OF Study</th>
                  <th>Client Name</th>
                  <th>Job Description</th>
                  {/* <th>Location Preference</th> */}
                </tr>
                {matchevents.length >= 0 ? (
                  matchevents.map(event => (
                    <tr key={event.id}>
                      <td>hiiii</td>
                      <td>{event.first_name} {event.last_name}</td>
                      <td>{event.field_of_study}</td>
                      <td>{event.clientName}</td>
                      <td>{event.jobDescription}</td>
                      {/* <td>{event.locationPreferences}</td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No data available</td>
                  </tr>
                )}
              </table>
              <table>
                <tr>
                  <td style={{ textAlign: 'right' }}>
                    <button>
                      <Link to="/jobposted">Show All</Link>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </section>

          <section className="admin-service-section">
            <div>
              <h2>Job Posted</h2>
              <table>
                <tr>
                  <th>Job Position</th>
                  <th>Recruiting Agency</th>
                  <th>Client Name</th>
                  <th>Job Description</th>
                  <th>Candidate Preference</th>
                  {/* <th>Location Preference</th> */}
                </tr>
                {events.length > 0 ? (
                  events.map(event => (
                    <tr key={event.id}>
                      <td>{event.jobPosition}</td>
                      <td>{event.recruitingAgency}</td>
                      <td>{event.clientName}</td>
                      <td>{event.jobDescription}</td>
                      <td>{event.candidatePreferences}</td>
                      {/* <td>{event.locationPreferences}</td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No data available</td>
                  </tr>
                )}
              </table>
              <table>
                <tr>
                  <td style={{ textAlign: 'right' }}>
                    <button>
                      <Link to="/jobposted">Show All</Link>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </section>

          <section className="admin-service-section">
            <div>
              <h2>New Profile</h2>
              <table>
                <tr>
                  <th>Name</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zipcode</th>
                  <th>role</th>
                  <th>Action</th>
                </tr>
                {canevents.length > 0 ? (
                  canevents.map(event => (
                    <tr key={event.id}>
                      <td>{event.F_name} {event.L_name}</td>
                      <td>{event.city}</td>
                      <td>{event.state}</td>
                      <td>{event.zipcode}</td>
                      <td>{event.role}</td>
                      <td>
                        <button style={{ marginRight: '0px' }}>Approve</button>
                        <button style={{ marginLeft: '0px' }}>Decline</button>
                        <button style={{ marginLeft: '0px' }}>Chat</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No data available</td>
                  </tr>
                )}
              </table>
              <table>
                <tr>
                  <td style={{ textAlign: 'right' }}>
                    <button>
                      <Link to="/newUser">Show All</Link>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ADashboard;
