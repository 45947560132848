import React from 'react';
import './style_aca.css';
import { Link } from 'react-router-dom';
import Navacademia from './Navacademia';
import { useState,useEffect } from 'react';
import axios from 'axios';
// function Profile_aca() {

//     const headingStyle = {
//         color: '#3B5D76',
//       };

//       const hrStyle = {
//         width: '98%',
//         marginLeft: '1px',
//         textAlign: 'left',
//       };

//   return (
//    <div>
//     <nav>
//         <Navacademia/>
//       </nav>
//    <h1 style={headingStyle}>University of Texas at Arlington</h1>
//     <p className="profile-description">Welcome to the University's Home Page. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel metus eu purus fermentum dapibus a eu dui.</p>
   

//     <hr style={hrStyle}  />
//     <br />

//      {/* <a className="grid-button" style="text-align: center; font-size: medium;" href="academia_update_profile.html">Want to edit your Academia profile ? Click Here</a> */}

//     <br />
//     <hr style={hrStyle} />
//     <br />


//     <div className="two-columns-grid">
//         <div>
//             <label for="myFile" className="dashboard-heading">
//                 All Faculty
//             </label>
//             <br/>
//             <div className="secondgrid">
//                 <div>
//                     <h2>Faculty Name </h2>
//                     <p>Faculty Qualification </p>
//                 </div>
//                 <div className="button-container">
//                     <label className="grid-button">Remove Faculty</label>
//                 </div>
//             </div>
            
//             <div className="secondgrid">
//                 <div>
//                     <h2>Faculty Name </h2>
//                     <p>Faculty Qualification </p>
//                 </div>
//                 <div className="button-container">
//                     <label className="grid-button">Remove Faculty</label>
//                 </div>
//             </div>
    
//         </div>

//         <div>
//             <label for="myFile" className="dashboard-heading">
//                 All Researches
//             </label>
    
//             <div className="secondgrid">
//                 <div>
//                     <h2>Research Title </h2>
//                     <p>Research Description</p>
//                 </div>
//             </div>
    
//             <div className="secondgrid">
//                 <div>
//                     <h2>Research Title </h2>
//                     <p>Research Description</p>
//                 </div>
//             </div>
//         </div>
    
//     </div>
//    </div>
//   );
// }
function Profile_aca() {

    const headingStyle = {
        color: '#3B5D76',
      };

      const hrStyle = {
        width: '98%',
        marginLeft: '1px',
        textAlign: 'left',
      };

      const [facultyData, setFacultyData] = useState([]);
      const [researchData, setResearchData] = useState([]);
      const [academiaInfo, setAcademiaInfo] = useState(null);
      const user_id = sessionStorage.getItem('uid');
      useEffect(() => {
        // Fetch faculty data from the PHP API
        axios.get(`https://mxs4224.uta.cloud/php/getAllAcademiaFaculty.php/?user_id=${user_id}`)
          .then((response) => {
            setFacultyData(response.data);
          })
          .catch((error) => {
            console.error('Error fetching faculty data:', error);
          });
    
        // Fetch research data from another API or endpoint
        axios.get(`https://mxs4224.uta.cloud/php/getAllAcademiaResearch.php/?user_id=${user_id}`)
          .then((response) => {
            setResearchData(response.data);
          })
          .catch((error) => {
            console.error('Error fetching research data:', error);
          });
      }, []);

      const [academiaId, setAcademiaId] = useState('');

  useEffect(() => {
    // Fetch the userData object from local storage
    const userDataFromStorage = localStorage.getItem('userData');
    
    // If userData exists in local storage, parse it and set the academia_id
    if (userDataFromStorage) {
      const userData = JSON.parse(userDataFromStorage);
      setAcademiaId(userData.aid);
    }
  }, []);

  //profile data
  useEffect(() => {
    // Fetch academia information based on the user ID from sessionStorage
   
    axios.get(`https://mxs4224.uta.cloud/php/profileDataFetch.php/?user_id=${user_id}`)
      .then((response) => {
        const data = response.data;
        if (data.message) {
          // Record does not exist, show the message
          setAcademiaInfo({ message: data.message });
        } else {
          // Record exists, store the data in state
          setAcademiaInfo(data);
        }
      })
      .catch((error) => {
        console.error('Error fetching academia data:', error);
      });
  }, []);


  const handleRemoveFaculty = (academiaId, facultyName) => {
    axios.post('https://mxs4224.uta.cloud/php/removeFaculty.php', { academia_Id: academiaId, Faculty_Name: facultyName })
      .then((res) => {
        // window.location.reload();
        // alert(res.data);
        console.log(res.data); // Display the response from the server
        // Handle the response or update facultyData in the state to reflect the removal
        
      })
      .catch((err) => {
        console.error('Error removing faculty:', err);
      });
  };


  return (
   <div>
    <nav>
         <Navacademia/>
    </nav>
    <div>
      {academiaInfo ? (

        
        <>

<div style={{ display: 'flex', alignItems: 'center' }}>
            <h1 style={headingStyle}>{academiaInfo.aid}</h1>
            <h1 style={{ ...headingStyle, marginLeft: '20px' }}>-</h1>
            <h1 style={{ ...headingStyle, marginLeft: '20px' }}>{academiaInfo.name}</h1>
          </div>

        {/* <div style={{ display: 'inline-block' }}>
            <h1 style={headingStyle}>{academiaInfo.aid}</h1>
          </div>
        <div style={{ display: 'inline-block' }}>
            <h1 style={headingStyle}>{academiaInfo.name}</h1>
          </div> */}
        
          <p>{academiaInfo.address}</p>
          <p className="profile-description">{academiaInfo.description}</p>
         
        </>
      ) : (
        <p>{academiaInfo?.message || 'Loading...'}</p>
      )}
    </div>
  
    <br />
    <hr style={hrStyle} />
    <br />

    <div className="two-columns-grid">
        <div>
            <label htmlFor="myFile" className="dashboard-heading">
                All Faculty
            </label>
            <br/>

            <div>

<div className="faculty-container">
  {facultyData && facultyData.length > 0 ? (
    facultyData.map((faculty) => (
      <div key={faculty.aid} className="secondgrid">
        <div>
          <h2>{faculty.faculty_name}</h2>
          <p>{faculty.faculty_Qualification}</p>
        </div>
        <div className="button-container">
          <button className="grid-button-profile" onClick={() => handleRemoveFaculty(faculty.aid, faculty.faculty_name)}>Remove Faculty</button>
        </div>
      </div>
    ))
  ) : (
    <div  className="secondgrid">No faculty data available.</div>
  )}
</div>


    </div>
    
        </div>

        <div>
            <label htmlFor="myFile" className="dashboard-heading">
                All Researches
            </label>

<div className="research-container">
  {researchData && researchData.length > 0 ? (
    researchData.map((research) => (
      <div key={research.aid} className="secondgrid">
        <div>
          <h2>{research.research_title}</h2>
          <p>{research.research_description}</p>
        </div>
      </div>
    ))
  ) : (
    <div  className="secondgrid">No research data available.</div>
  )}
</div>


      
        </div>
    
    </div>
   </div>
   
  );
}
export default Profile_aca;
