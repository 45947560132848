import React, { useState, useEffect } from 'react';
import './deiedit.css';
import Navdei from './Navdei';
import { Link } from 'react-router-dom';
import axios from "axios";

function formatDate(timestamp) {
  const dateObject = new Date(timestamp);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const Alldeijobs = () => {
  const [events, setEvents] = useState([]);

  // Fetch data from the backend API endpoint
  useEffect(() => {
    axios.get('https://djs4331.uta.cloud/php/fetch.php?tableName=createjob')
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const handleApprove = (jobId, status) => {
    console.log("handleApprove called with jobId:", jobId);
    // Prepare the data to be sent in the request body
    const data = {
      jobId: jobId,
      status: status ? 0 : 1, // Toggle the value of status
    };

    // Make a POST request to update the status field in the candidateDetails table
    axios.post('https://djs4331.uta.cloud/php/updateJ.php', data)
      .then((response) => {
        if (response.data.success === 1) {
          // Success: status updated successfully
          console.log('status updated successfully.');
          // Update the state to reflect the new status
          setEvents(events.map((event) => {
            if (event.jobId === jobId) {
              return { ...event, status: !status };
            }
            return event;
          }));
        } else {
          // Bookmark status update failed
          console.error('status update failed.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="dei-app">
      <nav>
        <Navdei />
      </nav>
      <div className="dei-app-body">
        <div className="dei-app-body-main-content">
          <section className="dei-service-section">
            <div>
              <h2>All Job Posts</h2>
              <table>
                <tr>
                  <th>Job Position</th>
                  <th>Recruiting Agency</th>
                  <th>Client Name</th>
                  <th>Job Description</th>
                  <th>Candidate Preferences</th>
                  <th>Location Preferences</th>
                  <th>Job Posted Date</th>
                  <th>Actions</th>
                </tr>
                {events.map(event => (
                  <tr key={event.jobId}>
                    <td>{event.jobPosition}</td>
                    <td>{event.recruitingAgency}</td>
                    <td>{event.clientName}</td>
                    <td>{event.jobDescription}</td>
                    <td>{event.candidatePreferences}</td>
                    <td>{event.locationPreferences}</td>
                    <td>{formatDate(event.jobPostedDate)}</td>
                    <td>
                      <button onClick={() => handleApprove(event.jobId, event.status)}>
                        {event.status === 1 ? 'Approved' : 'Approve'}
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Alldeijobs;
