import React from 'react';
import './style_rec.css';
import { Link } from 'react-router-dom';
import Navrecruiter from './Navrecruiter';
function Recruiters_rec() {
  return (
    <div><nav>
    <Navrecruiter/>
  </nav>
    <div className="two-columns-grid">
        
    <div>
        <label for="myFile" className="dashboard-heading">
            All Recruiters
        </label>

        <div className="secondgrid">
            <div>
                <h2>I Tech Solutions</h2>
                <h4>Arlington, Texas</h4>
                
            </div>
            <div className="button-container">
                <label className="grid-button">Request Connection</label>
            </div>
        </div>

        <div className="secondgrid">
            <div>
                <h2>Venito IT Consultancy</h2>
                <h4>Frisco, Texas</h4>
            </div>
            <div className="button-container">
                <label className="grid-button">Request Connection</label>
            </div>
        </div>


    </div>
    <div>
        <label for="myFile" className="dashboard-heading">
            My Recruiters
        </label>

        <div className="secondgrid">
            <div>
                <h2>MuleSoft Developers</h2>
                <h4>Dallas, Texas</h4>
            </div>
            <div className="button-container">
                <label className="grid-button">Remove Connection</label>
                <label className="grid-button">Chat</label>
            </div>
        </div>

        <div className="secondgrid">
            <div>
                <h2>Erode Pvt Limited</h2>
                <h4>Austin, Texas </h4>
            </div>
            <div className="button-container">
                <label className="grid-button">Remove Connection</label>
                <label className="grid-button">Chat</label>
            </div>
        </div>


    </div>
</div>
</div>
  );
}

export default Recruiters_rec;
