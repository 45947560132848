import React from 'react';
import './report.css'; // Make sure to import the CSS file
import chart1 from './images/chart1.png';
import chart2 from './images/chart2.png';
import chart3 from './images/chart3.png';
import Navadmin from './Navadmin';
const Report = () => {
  return (
    <div className="app">
      <nav>
        <Navadmin/>
      </nav>
      <div className="app-body">
        <div className="app-body-main-content">
          <section className="service-section">
            <h2>Reports</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <table>
                <tr>
                  <th>Recruiter Report</th>
                  <td>
                    <img src={chart1} alt="Recruiter Report" />
                  </td>
                </tr>
                <tr>
                  <th>Academia Report</th>
                  <td>
                    <img src={chart2} alt="Academia Report" />
                  </td>
                </tr>
                <tr>
                  <th>DEI Report</th>
                  <td>
                    <img src={chart3} alt="DEI Report" />
                  </td>
                </tr>
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Report;
